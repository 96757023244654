import { extractGraphQLErrorIfExists } from '@/utilities/errors/helpers';
import { formatMoney } from '@/utilities/money';
import { Routes, linkTo } from '@/utilities/routes';
import {
  formatFriendlyIso8601DateTimeStr,
  formatRelativeIso8601DateTimeStr,
} from '@packfleet/datetime';
import { Button, IconButton, Tooltip } from '@packfleet/ui';
import { RefObject, useRef } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { twMerge } from 'tailwind-merge';
import {
  ClaimFragment,
  ClaimSourceType,
  ClaimStatus,
  ListClaimsDocument,
  ListShipmentEventsDocument,
  useDeleteClaimMutation,
  useReviewClaimMutation,
} from '../../generated/graphql';
import DetailRow from '../details/DetailRow';
import DetailText from '../details/DetailText';
import Link from '../link/Link';
import WithAlert from '../modal/WithAlert';
import ButtonWithMenu from '../popover-menu/ButtonWithMenu';
import { ClaimRejection } from './ClaimRejection';
import { ClaimRequestAdditionalInfoDialog } from './ClaimRequestAdditionalInfoDialog';
import { EditClaimDialog } from './EditClaimDialog';
import {
  formatClaimStatus,
  getClassNamesForClaimStatus,
  isClaimFinalStatus,
} from './utilities';

export enum ClaimAmountType {
  Credit = 1,
  Charge = -1,
}

export function renderSourceLabel(claim: ClaimFragment) {
  switch (claim.sourceType) {
    case ClaimSourceType.Driver:
      return `🚛 ${claim.sourceUser?.name}`;
    case ClaimSourceType.Hub:
      return `📦 ${claim.sourceUser?.name}`;
    case ClaimSourceType.Internal:
      return '⚙️ Internal';
    case ClaimSourceType.ExternalCarrier:
      return 'External Carrier';
  }
}

export type Props = {
  claim: ClaimFragment;
  setError: (error: string | null | undefined) => void;
};

export const Claim = ({ claim, setError }: Props) => {
  const [reviewClaim] = useReviewClaimMutation();
  const [deleteClaim] = useDeleteClaimMutation();

  const menuRef: RefObject<HTMLElement> = useRef(null);

  return (
    <div key={claim.id} className="flex-1">
      <div className="flex flex-1 gap-4 items-start">
        <div className="text-secondary w-[5rem] text-right flex flex-col items-end shrink-0">
          <Tooltip title={formatFriendlyIso8601DateTimeStr(claim.createdAt)}>
            <Link
              href={linkTo(Routes.claim, { id: claim.id })}
              unstyled
              className="text-primary no-underline hover:underline"
            >
              <div
                className={twMerge(
                  'text-sm',
                  claim.amount && claim.amount > 0 && 'text-success',
                  claim.amount && claim.amount < 0 && 'text-danger',
                )}
              >
                {claim.amount && claim.currency
                  ? formatMoney(Math.abs(claim.amount), claim.currency)
                  : '£TBC'}
              </div>
              <div className="text-xs">
                {formatRelativeIso8601DateTimeStr(claim.createdAt)}
              </div>
            </Link>
          </Tooltip>
        </div>
        <div className="flex flex-1 gap-2 items-end py-2">
          <div className="text-info bg-info/20 rounded px-2 text-sm">
            {claim.type}
          </div>
          <div
            className={twMerge(
              'rounded px-2 text-sm',
              getClassNamesForClaimStatus(claim.status),
            )}
          >
            {formatClaimStatus(claim.status)}
          </div>
        </div>
        <div>
          <ButtonWithMenu
            button={
              <IconButton
                icon={BsThreeDots}
                iconClassName="text-2xl"
                ref={menuRef}
              />
            }
            menuItems={[
              {
                renderComponent: () => {
                  return (
                    <div>
                      <WithAlert
                        button={
                          <Button
                            mode="text"
                            s="small"
                            color="neutral"
                            disabled={isClaimFinalStatus(claim.status)}
                          >
                            {claim.sourceType ===
                              ClaimSourceType.ExternalCarrier &&
                            claim.status === ClaimStatus.InReview
                              ? 'Escalate to external carrier'
                              : 'Approve'}
                          </Button>
                        }
                        title="Are you sure?"
                        confirmText="Yes, approve"
                        cancelText="Cancel"
                        onConfirm={async () => {
                          let approvedStatus = ClaimStatus.Approved;
                          // If this source is an external carrier (because we believe that they are
                          // responsible for an issue with the shipment) then we should escalate the
                          // claim to the external carrier. This is done manually as the external
                          // carriers do not have an API available for claims. To track this operation
                          // when approving a external carrier claim transition the status from
                          // IN_REVIEW to IN_EXTERNAL_REVIEW.
                          //
                          // For other claims (or for claims that have already been escalated to the
                          // external carrier) we can transition the claim to the APPROVED status.
                          if (
                            claim.sourceType === ClaimSourceType.ExternalCarrier
                          ) {
                            if (claim.status === ClaimStatus.InReview) {
                              approvedStatus = ClaimStatus.InExternalReview;
                            }
                          }
                          const res = await extractGraphQLErrorIfExists(
                            reviewClaim({
                              variables: {
                                input: {
                                  id: claim.id,
                                  status: approvedStatus,
                                },
                              },
                              refetchQueries: [
                                ListClaimsDocument,
                                ListShipmentEventsDocument,
                              ],
                            }),
                            'reviewClaim',
                          );

                          setError(res.error ?? '');
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                renderComponent: () => {
                  return (
                    <ClaimRejection
                      claim={claim}
                      setError={setError}
                      trigger={
                        <Button
                          mode="text"
                          s="small"
                          color="neutral"
                          disabled={isClaimFinalStatus(claim.status)}
                        >
                          Reject
                        </Button>
                      }
                    />
                  );
                },
              },
              {
                renderComponent: () => {
                  return (
                    <div>
                      <ClaimRequestAdditionalInfoDialog
                        key={claim.id}
                        claim={claim}
                        trigger={
                          <Button
                            mode="text"
                            s="small"
                            color="neutral"
                            disabled={isClaimFinalStatus(claim.status)}
                            // Pass the click onto the menu so that the dropdown menu closes
                            // when we open the dialog
                            onClick={() => {
                              menuRef.current?.click();
                            }}
                          >
                            Request additional information
                          </Button>
                        }
                      />
                    </div>
                  );
                },
              },
              {
                renderComponent: () => {
                  return (
                    <div>
                      <EditClaimDialog
                        key={claim.id}
                        claim={claim}
                        trigger={
                          <Button
                            mode="text"
                            s="small"
                            color="neutral"
                            disabled={isClaimFinalStatus(claim.status)}
                            // Pass the click onto the menu so that the dropdown menu closes
                            // when we open the dialog
                            onClick={() => {
                              menuRef.current?.click();
                            }}
                          >
                            Edit
                          </Button>
                        }
                      />
                    </div>
                  );
                },
              },
              {
                renderComponent: () => {
                  return (
                    <div>
                      <WithAlert
                        button={
                          <Button
                            mode="text"
                            s="small"
                            color="neutral"
                            disabled={isClaimFinalStatus(claim.status)}
                          >
                            Delete
                          </Button>
                        }
                        title="Are you sure?"
                        confirmText="Yes, delete"
                        cancelText="Cancel"
                        onConfirm={async () => {
                          const res = await extractGraphQLErrorIfExists(
                            deleteClaim({
                              variables: {
                                input: {
                                  id: claim.id,
                                },
                              },
                              refetchQueries: [ListClaimsDocument],
                            }),
                            'deleteClaim',
                          );

                          setError(res.error ?? '');
                        }}
                      />
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
      <div className="mt-4 mb-6 px-4">
        <DetailRow name="Source" data={renderSourceLabel(claim)}>
          {(props) => <DetailText {...props} />}
        </DetailRow>
        <DetailRow name="Comments" data={claim.reason}>
          {(props) => <DetailText {...props} className="whitespace-pre-wrap" />}
        </DetailRow>
        {claim.costToPackfleet && (
          <DetailRow
            name="Cost to Packfleet"
            data={formatMoney(Math.abs(claim.costToPackfleet), 'GBP')}
          >
            {(props) => <DetailText {...props} />}
          </DetailRow>
        )}
        {claim.chargeSourceUser && (
          <DetailRow name="Source User Charged" data="Yes">
            {(props) => <DetailText {...props} />}
          </DetailRow>
        )}
        {claim.refundFees && (
          <DetailRow name="Shipping Fees Refunded" data="Yes">
            {(props) => <DetailText {...props} />}
          </DetailRow>
        )}
      </div>
    </div>
  );
};
