import ColorHash from 'color-hash';
import md5 from 'md5';

export enum Colors {
  brandBright = '#42FF00',
  brandDark = '#194128',
  info = '#148dc0',
  success = '#0c7923',
  warning = '#d78c1b',
  danger = '#bf1848',
  neutral = '#363b38',
  white = '#ffffff',

  infoLight = '#bfe5f5',
  successLight = '#c8f5de',
  warningLight = '#faead7',
  dangerLight = '#fccedc',
}

// stringToColor converts a string into a hexadecimal color
export function stringToColor(str: string) {
  const ch = new ColorHash({
    lightness: 0.4,
    saturation: [0.6, 1],
  });
  return ch.hex(md5(str));
}

// stringToColorBright converts a string into a bright hexadecimal color
export function stringToColorBright(str: string) {
  const ch = new ColorHash({
    lightness: 0.8,
    saturation: [0.6, 1],
  });
  return ch.hex(md5(str));
}
