import Button from '@components/button/Button';
import DetailRow from '@components/details/DetailRow';
import DetailText from '@components/details/DetailText';
import Dialog from '@components/dialog/Dialog';
import { COMPLETION_STATUS } from '@components/tasks/AddressFeedbackFromDriver/constants';
import { getRefetchQueries } from '@components/tasks/utils';
import {
  AddressFeedbackFromDriverFragment,
  AddressFeedbackType,
  useCompleteTaskMutation,
  useDismissTaskMutation,
  useGetAddressFeedbackInfoQuery,
} from 'generated/graphql';
import Link from 'next/link';
import { useEffect } from 'react';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { Routes, linkTo } from 'utilities/routes';
import { useTasksContext } from '../useTasksContext';
import { AddressFeedbackDetails } from './utils/AddressFeedbackDetails';
import { CollectionDetails } from './utils/CollectionDetails';
import { RouteActivity } from './utils/RouteActivity';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDetails } from './utils/ShipmentDetails';
import { TaskActionButton } from './utils/TaskActionButton';
import { getMapMarkers } from './utils/getMapMarkets';

type Props = {
  task: AddressFeedbackFromDriverFragment;
  taskId: string;
};

export const AddressFeedbackFromDriver = ({ task, taskId }: Props) => {
  const {
    data,
    error: unhandledError,
    loading,
  } = useGetAddressFeedbackInfoQuery({
    variables: {
      input: { routeActivityId: task.routeActivity.routeActivityId },
    },
  });
  const { action } = useTasksContext();
  const [dismissTask] = useDismissTaskMutation();
  const [completeTask] = useCompleteTaskMutation();

  const error =
    unhandledError?.message || data?.getAddressFeedbackInfo.error?.message;
  const feedbackInfo = data?.getAddressFeedbackInfo.info;

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: action.clearTaskInfoOnMap, action.displayTaskInfoOnMap, task
  useEffect(() => {
    if (feedbackInfo) {
      action.displayTaskInfoOnMap(getMapMarkers(task));
    }
    return () => action.clearTaskInfoOnMap();
  }, [feedbackInfo]);

  const correctGeocodingUrl = feedbackInfo?.shipmentId
    ? linkTo(Routes.shipment, {
        id: feedbackInfo.shipmentId,
        'task-id': taskId,
      })
    : feedbackInfo?.collectionLocationId && feedbackInfo.merchant.id
      ? linkTo(Routes.merchantCollectionLocation, {
          id: feedbackInfo.merchant.id,
          locId: feedbackInfo.collectionLocationId,
          'task-id': taskId,
        })
      : undefined;

  const onPostedInSlack = async () =>
    extractGraphQLErrorIfExists(
      completeTask({
        variables: {
          input: {
            taskId,
            completionResult: COMPLETION_STATUS.POSTED_IN_SLACK,
          },
        },
        refetchQueries: getRefetchQueries(taskId),
      }),
      'completeTask',
    );

  const onReportedToMerchant = async () =>
    extractGraphQLErrorIfExists(
      completeTask({
        variables: {
          input: {
            taskId,
            completionResult: COMPLETION_STATUS.POSTED_IN_SLACK,
          },
        },
        refetchQueries: getRefetchQueries(taskId),
      }),
      'completeTask',
    );

  const onDismiss = () =>
    extractGraphQLErrorIfExists(
      dismissTask({ variables: { input: { taskId } } }),
      'dismissTask',
    );

  return (
    <div className="flex flex-col gap-4 py-3">
      {error ? <p className="text-warning">{error}</p> : null}

      {feedbackInfo?.shipmentId ? (
        <ShipmentDetails shipmentId={feedbackInfo?.shipmentId} />
      ) : null}

      {feedbackInfo?.collectionId && feedbackInfo.merchant.id ? (
        <CollectionDetails
          collectionId={feedbackInfo?.collectionId}
          organizationId={feedbackInfo.merchant.id}
        />
      ) : null}

      <SectionHeading title="Context" />

      <AddressFeedbackDetails
        loading={loading}
        type={task.type}
        driver={task.routeActivity.driver}
        notes={task.notes}
        proofOfDelivery={feedbackInfo?.proofOfDelivery}
      />

      <RouteActivity
        title="Their movements for this stop:"
        routeActivity={task.routeActivity}
      />

      <SectionHeading title="Actions" />
      <TaskActionButton
        role="secondary"
        text="Dismiss"
        taskIdToCompleteOnClick={taskId}
        onClick={onDismiss}
      />

      {correctGeocodingUrl ? (
        <TaskActionButton
          role="primary"
          text="Fix coordinates"
          href={correctGeocodingUrl}
        />
      ) : null}

      {showCreateAreaOverride(task.type) ? (
        <TaskActionButton
          role="primary"
          text="Create area override"
          href={linkTo(Routes.areaOverridesNew, {
            'task-id': taskId,
          })}
        />
      ) : null}

      {showPostToSlack(task.type) ? (
        <Dialog
          trigger={
            <div className="px-3 my-2">
              <Button
                className="w-full"
                color="primary"
                s="small"
                mode="outline"
              >
                Post in #address-feedback
              </Button>
            </div>
          }
          title={`Post in slack`}
          description="Click done once you've posted."
          confirmText="Done"
          confirmColor="info"
          cancelText="Cancel"
          onConfirm={onPostedInSlack}
        >
          <Link
            href="slack://channel?team=T022MLMK38F&id=C058VRMQ8KU"
            className="text-link"
          >
            #address-feedback
          </Link>
        </Dialog>
      ) : null}

      {showReportToMerchant(task.type) ? (
        <Dialog
          trigger={
            <div className="px-3 my-2">
              <Button
                className="w-full"
                color="primary"
                s="small"
                mode="outline"
              >
                Report to merchant
              </Button>
            </div>
          }
          title="Report to merchant"
          description="Click done once you've reached out."
          confirmText="Done"
          confirmColor="info"
          cancelText="Cancel"
          onConfirm={onReportedToMerchant}
        >
          <DetailRow name="Merchant" data={feedbackInfo?.merchant.name}>
            {(props) => <DetailText {...props} enableCopyToCliboard />}
          </DetailRow>
          <DetailRow name="Email" data={feedbackInfo?.merchant.email}>
            {(props) => <DetailText {...props} enableCopyToCliboard />}
          </DetailRow>
        </Dialog>
      ) : null}
    </div>
  );
};

const REPORT_TO_MERCHANT_TYPES = new Set([
  AddressFeedbackType.SomethingElse,
  AddressFeedbackType.WrongAddress,
  AddressFeedbackType.ReceptionWouldntTakeIt,
]);
const showReportToMerchant = (type: AddressFeedbackType) =>
  REPORT_TO_MERCHANT_TYPES.has(type);

const POST_TO_SLACK_TYPES = new Set([
  AddressFeedbackType.SomethingElse,
  AddressFeedbackType.WrongAddress,
]);
const showPostToSlack = (type: AddressFeedbackType) =>
  POST_TO_SLACK_TYPES.has(type);

const AREA_OVERRIDE_TYPES = new Set([
  AddressFeedbackType.HardToFindParking,
  AddressFeedbackType.HardToFollowAccessInstructions,
  AddressFeedbackType.ReceptionWouldntTakeIt,
  AddressFeedbackType.SomethingElse,
  AddressFeedbackType.TowerBlockChallenging,
  AddressFeedbackType.WrongAddress,
]);
const showCreateAreaOverride = (type: AddressFeedbackType) =>
  AREA_OVERRIDE_TYPES.has(type);
