import classNames from 'classnames';
import cs from 'classnames';
import React, { PropsWithChildren } from 'react';
import { IconType } from 'react-icons';
import Loading from '../loading/Loading';
import { ComponentStylingOptions, ReusableComponentProps } from '../types';

type Size = 'large' | 'normal' | 'small' | 'smaller' | 'tiny';
export type ColorScheme =
  | 'info'
  | 'warning'
  | 'danger'
  | 'success'
  | 'brand'
  | 'neutral'
  | 'primary';
type Mode = 'primary' | 'secondary' | 'outline' | 'text';
type Variant = 'normal' | 'pill';
type IconPosition = 'left' | 'right';

export type BaseButtonProps<T> = React.HTMLProps<T> &
  ReusableComponentProps & {
    component?: React.ElementType | keyof JSX.IntrinsicElements;
    childWrapper?: React.ElementType | keyof JSX.IntrinsicElements;
    childWrapperClassName?: string;
    mode?: Mode;
    s?: Size;
    color?: ColorScheme;
    variant?: Variant;
    icon?: IconType;
    iconPosition?: IconPosition;
    iconClassName?: string;
    square?: boolean;
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
    componentProps?: React.ComponentProps<
      React.ElementType | keyof JSX.IntrinsicElements
    >;
  };

function BaseButton<T>({
  component: Component = 'button',
  childWrapper: ChildWrapper = 'span',
  childWrapperClassName,
  loading,
  loadingText,
  className,
  color = 'success' as ColorScheme,
  mode = 'primary' as Mode,
  s = 'normal' as Size,
  variant = 'normal',
  icon: Icon,
  iconClassName,
  iconPosition = 'left',
  unstyled,
  style,
  disabled,
  square,
  children,
  componentProps,
  type = 'button',
  ...props
}: PropsWithChildren<BaseButtonProps<T>>) {
  const bgColors = {
    info: 'bg-info',
    neutral: 'bg-neutral',
    warning: 'bg-warning',
    danger: 'bg-danger',
    success: 'bg-success',
    brand: 'bg-brand',
    primary: 'bg-primary',
  };
  const bgColorsSecondary = {
    info: 'bg-[#F0F8FB]',
    neutral: 'bg-neutralLight',
    warning: 'bg-warningLight',
    danger: 'bg-[#FFEBEA]',
    success: 'bg-[#E5F3F1]',
    brand: 'bg-[#F4FBF6]',
    primary: 'bg-secondary',
  };
  const textColors = {
    info: 'text-info',
    neutral: 'text-neutral',
    warning: 'text-warning',
    danger: 'text-danger',
    success: 'text-success',
    brand: 'text-brand',
    primary: 'text-primary',
  };
  const borderColors = {
    info: 'border-primary',
    neutral: 'border-primary',
    warning: 'border-primary',
    danger: 'border-primary',
    success: 'border-primary',
    brand: 'border-primary',
    primary: 'border-primary',
  };
  const borderColorsSecondary = {
    info: 'border-primary',
    neutral: 'border-primary',
    warning: 'border-primary',
    danger: 'border-[#FF3B30]/30',
    success: 'border-[#008C73]/30',
    brand: 'border-brand/30',
    primary: 'border-primary',
  };
  const modeClasses = {
    primary: `${bgColors[color]} text-primaryInverted`,
    secondary: `${bgColorsSecondary[color]} ${textColors[color]} border ${borderColorsSecondary[color]}`,
    outline: cs(
      `shadow-sm bg-primary border ${textColors[color]} ${borderColors[color]}`,
      {
        'transition-colors duration-300 ease-in-out hover:bg-secondary':
          !disabled,
      },
    ),
    text: `transition-opacity duration-300 ease-in-out ${textColors[color]} hover:opacity-80`,
  };
  const sizeClasses = {
    tiny: 'text-xs h-6',
    smaller: 'text-sm h-8',
    small: 'h-10',
    normal: 'h-12',
    large: 'h-14',
  };
  const iconSizeClasses = {
    tiny: 'text-sm',
    smaller: 'text-base',
    small: 'text-lg',
    normal: 'text-lg',
    large: 'text-xl',
  };
  const widthClasses = {
    tiny: 'w-6',
    smaller: 'w-8',
    small: 'w-10',
    normal: 'w-12',
    large: 'w-14',
  };
  const paddingClasses = {
    tiny: 'px-1.5',
    smaller: 'px-2.5',
    small: 'px-3',
    normal: 'px-4',
    large: 'px-6',
  };
  const variantClasses = {
    normal: 'rounded',
    pill: 'rounded-full',
  };

  const options: ComponentStylingOptions = {
    classNames: className,
    style: {
      lineHeight: '100%',
      ...style,
    },
  };

  if (!unstyled) {
    options.classNames = cs(
      'inline-block font-medium flex-shrink-0',
      options.classNames,
      modeClasses[mode],
      sizeClasses[s],
      variantClasses[variant],
      { [widthClasses[s]]: square },
      { 'opacity-50': disabled },
      { [paddingClasses[s]]: mode !== 'text' && !!children },
    );
  }

  return (
    <Component
      className={options.classNames}
      style={options.style}
      disabled={disabled}
      type={type}
      {...componentProps}
      {...props}
    >
      <ChildWrapper
        className={classNames(
          'flex h-full flex-shrink-0 items-center justify-center leading-none',
          childWrapperClassName,
        )}
      >
        {Icon && iconPosition === 'left' ? (
          <Icon
            className={cs(iconSizeClasses[s], iconClassName, {
              'mr-1': !!children,
            })}
          />
        ) : null}
        {loading ? (
          <Loading
            when={loading}
            size={s}
            className="mr-2"
            color={mode === 'outline' || mode === 'text' ? 'dark' : 'light'}
          />
        ) : null}
        {loadingText && loading ? null : children}
        {Icon && iconPosition === 'right' ? (
          <Icon
            className={cs(iconSizeClasses[s], iconClassName, {
              'ml-1': !!children,
            })}
          />
        ) : null}
      </ChildWrapper>
    </Component>
  );
}

export default BaseButton;
