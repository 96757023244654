import {
  InternalRefetchQueriesInclude,
  InternalRefetchQueryDescriptor,
} from '@apollo/client';
import {
  formatFriendlyPlainDateShort,
  parsePlainDate,
} from '@packfleet/datetime';
import {
  AddressValidationEventType,
  GetTaskDocument,
  ReadOptimizedRouteVehiclePolylinesDocument,
  RouteActivitiesNeedAttentionFragment,
  RouteActivitiesNeedAttentionReason,
  TaskInfoFragment,
  TaskStatus,
  TaskType,
} from 'generated/graphql';
import { pluralize } from 'utilities/string';
import { LIST_SUPPORTED_TASKS } from './constants';

export const getTaskStatusFriendlyName = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.Queued:
      return 'Queued';
    case TaskStatus.InProgress:
      return 'In progress';
    case TaskStatus.Complete:
      return 'Complete';
    case TaskStatus.Dismissed:
      return 'Dismissed';
  }

  const exhaustiveCheck: never = status; // If this line errors we missed a case
  throw new Error(`Unknown status: ${exhaustiveCheck}`);
};

export const getTaskInfo = (task: TaskInfoFragment['task'] | undefined) => {
  switch (task?.__typename) {
    case undefined:
      return {
        title: null,
        description: null,
        subject: null,
        dismissActionEnabled: true,
      };
    case 'AddressFeedbackFromDriver':
      return {
        title: getTaskTypeFriendlyName(TaskType.AddressFeedback),
        description: null,
        subject: task.routeActivity.driver?.name,
        dismissActionEnabled: true,
      };
    case 'CollectionsNotOnRoute': {
      const date = formatFriendlyPlainDateShort(parsePlainDate(task.date));
      const pluralized = pluralize(
        task.collections.length,
        'Collection',
        'Collections',
      );
      return {
        title: `${pluralized} not on route`,
        description: `${pluralized} booked have not been added to any driver's route. Add to a route or skip if not needed.`,
        subject: `${date} (${task.collections.length})`,
        dismissActionEnabled: false,
      };
    }
    case 'ProofOfCompletionReview':
      return {
        title: getTaskTypeFriendlyName(TaskType.ProofOfCompletionReview),
        description: null,
        subject: task.routeActivity.driver?.name,
        dismissActionEnabled: true,
      };
    case 'CollectionCancelled':
      return {
        title: getTaskTypeFriendlyName(TaskType.CollectionCancelled),
        description: 'The merchant has skipped this collection',
        subject: `${task.organizationName} ${task.locationName || ''}`,
        dismissActionEnabled: false,
      };
    case 'CollectionOnMoreThanOneRoute':
      return {
        title: `Collection on ${task.routeActivities.length} routes`,
        description: 'This collection may be attended multiple times',
        subject: `${task.organizationName} ${task.locationName || ''}`,
        dismissActionEnabled: false,
      };
    case 'IntercomItemForAttention':
      return {
        title: getTaskTypeFriendlyName(TaskType.IntercomItemForAttention),
        description: null,
        subject: task.id,
        dismissActionEnabled: true,
      };
    case 'PackStuckInDepot':
      return {
        title: getTaskTypeFriendlyName(TaskType.PacksStuckInDepot),
        description:
          'A pack is still around after it should have been delivered',
        subject: `${task.trackingPhrase} (${task.organizationName})`,
        dismissActionEnabled: false,
      };
    case 'PacksLeftInVan':
      return {
        title: `Undelivered ${pluralize(
          task.packs.length,
          'pack',
          'packs',
        )} left in van`,
        description: `${task.packs.length} ${pluralize(
          task.packs.length,
          'pack',
          'packs',
        )} still left in ${task.driverName}'s van after finishing their route.`,
        subject: `Driver: ${task.driverName}`,
        dismissActionEnabled: false,
      };
    case 'RouteActivitiesNeedAttention': {
      const count = task.routeActivities.length;
      const type: string = getRouteActivitiesNeedAttentionType(task, count);
      const date = formatFriendlyPlainDateShort(parsePlainDate(task.date));

      return {
        title: `${count} ${type} need${pluralize(count, 's', '')} attention`,
        description: getRouteActivitiesNeedAttentionDescription(task),
        subject: `${date}`,
        dismissActionEnabled: false,
      };
    }
    case 'ShipmentDateChange': {
      const to = formatFriendlyPlainDateShort(parsePlainDate(task.toDate));
      return {
        title: getTaskTypeFriendlyName(TaskType.ShipmentDateChange),
        description: 'A shipment is on a route for the wrong date.',
        subject: `${task.trackingPhrase} -> ${to}`,
        dismissActionEnabled: false,
      };
    }
    case 'ShipmentNoLocationInDepot':
      return {
        title: getTaskTypeFriendlyName(TaskType.ShipmentNoLocationInDepot),
        description:
          "A shipment is in the depot but doesn't have a shelf location.",
        subject: `${task.trackingPhrase} (${task.organizationName})`,
        dismissActionEnabled: false,
      };
    case 'ShipmentsNotOnRoute': {
      const date = formatFriendlyPlainDateShort(parsePlainDate(task.date));
      const pluralized = pluralize(
        task.shipments.length,
        'Shipment',
        'Shipments',
      );
      return {
        title: `${pluralized} not on route`,
        description: `${pluralized} scheduled for a date are not on the corresponding routes.`,
        subject: `${date} (${task.shipments.length})`,
        dismissActionEnabled: false,
      };
    }
    case 'ShipmentOnHold':
      return {
        title: getTaskTypeFriendlyName(TaskType.ItemsOnHold),
        description:
          "This shipment has a pack with the status 'On Hold'. This status should be used whilst we're investigating what to do next.",
        subject: `${task.trackingPhrase} (${task.organizationName})`,
        dismissActionEnabled: false,
      };
    case 'ShipmentOnMoreThanOneRoute':
      return {
        title: `Shipment is on ${task.routeActivities.length} routes`,
        description: 'This shipment is being delivered more than once',
        subject: `${task.trackingPhrase} (${task.organizationName})`,
        dismissActionEnabled: false,
      };
    case 'ShipmentsOnRouteNotCollected': {
      const date = formatFriendlyPlainDateShort(parsePlainDate(task.date));
      const pluralized = pluralize(
        task.shipments.length,
        'Shipment',
        'Shipments',
      );
      return {
        title: `${pluralized} on route but not collected`,
        description: `${pluralized} on a route, but we didn't collect from the merchant.`,
        subject: `${date} (${task.shipments.length})`,
        dismissActionEnabled: false,
      };
    }
    case 'ShipmentOnStartedRouteInDepot':
      return {
        title: getTaskTypeFriendlyName(TaskType.ShipmentOnActiveRouteInDepot),
        description: 'This may mean a driver has left without a pack.',
        subject: `${task.trackingPhrase} (${task.organizationName})`,
        dismissActionEnabled: false,
      };
    case 'WarehousePick':
      return {
        title: getTaskTypeFriendlyName(TaskType.WarehousePick),
        description: null,
        subject: task.vehicleId,
        dismissActionEnabled: true,
      };
    case 'AddressReview': {
      const merchantName =
        task.eventType === AddressValidationEventType.Shipment
          ? task.shipment?.merchantName
          : task.collectionLocation?.merchantName;
      return {
        title: getTaskTypeFriendlyName(TaskType.AddressReview),
        description:
          task.eventType === AddressValidationEventType.Shipment
            ? "There's potentially an issue with the address for this shipment"
            : "There's potentially an issue with the address for this collection location",
        subject:
          task.eventType === AddressValidationEventType.Shipment
            ? `${task.shipment?.trackingNumber} (${merchantName})`
            : task.collectionLocation?.locationName
              ? `Collection location: ${task.collectionLocation.locationName} (${merchantName})`
              : `Collection location for: ${merchantName}`,
        dismissActionEnabled: true,
      };
    }
    case 'VehicleNotEnded':
      return {
        title: getTaskTypeFriendlyName(TaskType.VehicleNotEnded),
        description:
          'The driver has finished their stops but not ended their route',
        subject: task.vehicle.driver?.name,
        dismissActionEnabled: false,
      };
    case 'ClaimReviewRequired':
      return {
        title: getTaskTypeFriendlyName(TaskType.ClaimReviewRequired),
        description: null,
        subject: task.claimTrackingNumber
          ? `${task.claimTrackingNumber} (${task.organizationName})`
          : task.claimCollectionDate && task.claimCollectionLocationName
            ? `${task.claimCollectionDate} - ${task.claimCollectionLocationName} (${task.organizationName})`
            : `(${task.organizationName})`,
        dismissActionEnabled: false,
      };
  }

  const exhaustiveCheck: never = task; // If this line errors we missed a case
  throw new Error(`Unknown status: ${exhaustiveCheck}`);
};

export const getTaskTypeFriendlyName = (taskType: TaskType): string => {
  switch (taskType) {
    case TaskType.AddressFeedback:
      return 'Address feedback';
    case TaskType.AddressReview:
      return 'Address review';
    case TaskType.ClaimReviewRequired:
      return 'Claim review required';
    case TaskType.CollectionCancelled:
      return 'Collection cancelled';
    case TaskType.CollectionMoreThanOneRoute:
      return 'Collection on more than one route';
    case TaskType.CollectionNotOnRoute:
      return 'Collection not on route';
    case TaskType.IntercomItemForAttention:
      return 'Intercom item for attention';
    case TaskType.ItemsOnHold:
      return 'Shipment on hold';
    case TaskType.PacksLeftInVan:
      return 'Packs left in van';
    case TaskType.PacksStuckInDepot:
      return 'Pack stuck';
    case TaskType.ProofOfCompletionReview:
      return 'Proof of delivery to review';
    case TaskType.RouteActivitiesNeedAttention:
      return 'Route activities need attention';
    case TaskType.ShipmentDateChange:
      return 'Shipment date change';
    case TaskType.ShipmentMoreThanOneRoute:
      return 'Shipment on more than one route';
    case TaskType.ShipmentNotOnRoute:
      return 'Shipment not on route';
    case TaskType.ShipmentNoLocationInDepot:
      return 'Shipment has no location in hub';
    case TaskType.ShipmentOnActiveRouteInDepot:
      return 'Shipment on a started route in the hub';
    case TaskType.ShipmentOnRouteNotCollected:
      return 'Shipment on route but not collected';
    case TaskType.VehicleNotEnded:
      return 'Route not ended';
    case TaskType.WarehousePick:
      return 'Warehouse pick';
  }
};

export const getRefetchQueries = (
  taskId?: string | null | undefined,
): InternalRefetchQueryDescriptor[] => {
  const queries: InternalRefetchQueriesInclude = [LIST_SUPPORTED_TASKS];

  if (taskId) {
    queries.push({ query: GetTaskDocument, variables: { input: { taskId } } });
  }

  return queries;
};

export const getRefetchRouteQueries = (
  vehicleIds: number[],
): InternalRefetchQueryDescriptor[] =>
  vehicleIds.map((vehicleId) => ({
    query: ReadOptimizedRouteVehiclePolylinesDocument,
    variables: { id: vehicleId.toString() },
  }));

function getRouteActivitiesNeedAttentionDescription({
  reason,
}: RouteActivitiesNeedAttentionFragment) {
  switch (reason) {
    case RouteActivitiesNeedAttentionReason.CollectionsRunningLate:
      return 'We are running far too late for these collections.';
    case RouteActivitiesNeedAttentionReason.DeliveriesRunningLate:
      return 'These are scheduled to happen way after our agreed delivery window.';
    case RouteActivitiesNeedAttentionReason.LateReturningNationwideCollections:
      return 'We are returning to the depot after the cutoff time for these nationwide collections.';
  }
}

function getRouteActivitiesNeedAttentionType(
  { reason }: RouteActivitiesNeedAttentionFragment,
  count: number,
): string {
  switch (reason) {
    case RouteActivitiesNeedAttentionReason.CollectionsRunningLate:
      return pluralize(count, 'collection', 'collections');
    case RouteActivitiesNeedAttentionReason.DeliveriesRunningLate:
      return pluralize(count, 'delivery', 'deliveries');
    case RouteActivitiesNeedAttentionReason.LateReturningNationwideCollections:
      return pluralize(
        count,
        'nationwide collection',
        'nationwide collections',
      );
  }
}
