import DetailAction, {
  DetailActionType,
} from '@components/details/DetailAction';
import Heading from '@components/heading/Heading';
import cs from 'classnames';
import classNames from 'classnames';
import { HTMLProps, ReactNode } from 'react';
import { TbPlus } from 'react-icons/tb';

type Props = {
  title?: ReactNode;
  description?: ReactNode;
  children: ReactNode;
  actions?: DetailActionType[];
  close?: () => void;
  className?: string;
  banner?: { text: string; color: 'warning' | 'danger' | 'info' } | null;
} & Omit<HTMLProps<HTMLDivElement>, 'title'>;

export const Details = ({
  title,
  description,
  children,
  actions = [],
  close,
  className,
  banner,
}: Props) => {
  return (
    <div className={cs(className)}>
      {banner ? (
        <div
          className={classNames(
            'px-1.5 py-1 text-xs leading-none text-primaryInverted rounded-tl-md rounded-tr-md -mb-2',
            {
              'bg-warning': banner.color === 'warning',
              'bg-info': banner.color === 'info',
              'bg-danger': banner.color === 'danger',
            },
          )}
        >
          {banner.text}
        </div>
      ) : null}
      <div className="border-b border-b-primary p-6">
        <div className="flex items-center gap-4">
          {title ? (
            <Heading level={1} className="flex-1">
              {title}
            </Heading>
          ) : null}
          {actions.map((a, index) => (
            <DetailAction key={index} action={a} />
          ))}
          {close && (
            <div
              className="cursor-pointer rounded-md p-1 hover:bg-sand"
              onClick={close}
            >
              <TbPlus size={24} className="rotate-45 stroke-textPrimary" />
            </div>
          )}
        </div>
        {description ? (
          <p className="mt-1 text-secondary">{description}</p>
        ) : null}
      </div>

      <div className="flex grow flex-col gap-8 overflow-auto p-6">
        {children}
      </div>
    </div>
  );
};
