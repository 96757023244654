import cs from 'classnames';
import React, { ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

type Props = {
  onClose: () => void;
  className?: string;
  children?: ReactNode;
};
const ModalContent = ({ children, onClose, className }: Props) => {
  return (
    <div
      className={cs(
        'relative mx-auto w-full rounded border border-primary bg-primary p-8',
        className,
      )}
    >
      <div
        className="text-heading color-text-secondary absolute"
        style={{ top: 8, right: 8 }}
      >
        <button onClick={onClose}>
          <FiX />
        </button>
      </div>
      {children}
    </div>
  );
};

export default ModalContent;
