import cs from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';

export enum ModalStyle {
  light,
  dark,
}

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  modalStyle?: ModalStyle;
  background?: string;
  opaque?: boolean;
  appElement?: HTMLElement;
  children?: ReactNode;
};

const Modal = ({
  children,
  modalStyle = ModalStyle.dark,
  background,
  opaque = false,
  onClose,
  isOpen,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [firstRenderDone, setFirstRenderDone] = useState(false);

  useEffect(() => {
    if (opaque) return;
    if (!ref.current || !childrenRef.current) return;
    const refc = ref.current;
    const close = (e: MouseEvent) => {
      if (childrenRef.current?.contains(e.target as Node)) {
        return;
      }
      onClose();
    };
    refc.addEventListener('mousedown', close);
    return () => {
      refc.removeEventListener('mousedown', close);
    };
  }, [opaque, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const hasWindow = typeof window !== 'undefined';
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: hasWindow
  useEffect(() => {
    setFirstRenderDone(true);
  }, [hasWindow]);

  if (!firstRenderDone) return null;

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={cs('flex justify-center p-4 md:items-center')}
      style={{
        position: 'fixed',
        display: isOpen ? '' : 'none',
        overflow: 'auto',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: background
          ? background
          : modalStyle === ModalStyle.dark
            ? `rgba(0, 0, 0, ${opaque ? 1 : 0.5}`
            : `rgba(255, 255, 255, ${opaque ? 1 : 0.5})`,
        zIndex: 900,
      }}
    >
      {opaque ? (
        <div
          className="text-title color-text-primary absolute"
          style={{ top: 16, right: 16 }}
        >
          <button onClick={onClose}>
            <FiX />
          </button>
        </div>
      ) : null}
      <div ref={childrenRef} className="modal-fadeInUpAnimation w-full">
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
