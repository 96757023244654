import {
  EmployerType,
  SearchUsersFilter,
  UserFragment,
  useSearchUsersLazyQuery,
} from '@/generated/graphql';
import { ReusableComponentProps } from '@packfleet/ui';
import {
  GroupBase,
  OptionProps,
  OptionsOrGroups,
  SingleValue,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { twMerge } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

export type User = Pick<UserFragment, 'id' | 'name' | 'driver'> & {
  staffMember?:
    | (Pick<NonNullable<UserFragment['staffMember']>, 'role' | 'id'> & {
        employer?:
          | Pick<
              NonNullable<NonNullable<UserFragment['staffMember']>['employer']>,
              'id' | 'name' | 'type'
            >
          | null
          | undefined;
      })
    | null;
};

type Props<
  Option = User,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = ReusableComponentProps & {
  id?: string;
  filters?: SearchUsersFilter;
  value: User | null | undefined;
  defaultOptions?: OptionsOrGroups<Option, Group> | boolean;
  onChange: (user: SingleValue<User> | null) => void;
  isClearable?: boolean;
  required?: boolean;
  showTags?: boolean;
};

const { Option } = components;
const UserOption = (props: OptionProps<User>) => (
  <Option {...props}>
    <div className="flex gap-2">
      <span className="flex-1">{props.data.name}</span>
      {props.data.staffMember?.role && (
        <div className="rounded px-2 py-1 text-xs font-bold text-info bg-infoLight">
          {props.data.staffMember.role}
        </div>
      )}
      {props.data.staffMember?.employer?.name && (
        <div
          className={twMerge(
            'rounded px-2 py-1 text-xs font-bold text-light bg-[purple]/20',
            props.data.staffMember?.employer.type === EmployerType.Packfleet
              ? 'bg-brandBright text-brandDark'
              : undefined,
          )}
        >
          {props.data.staffMember.employer.name}
        </div>
      )}
    </div>
  </Option>
);

export const UsersSelect = ({
  id,
  className,
  filters,
  value,
  defaultOptions,
  onChange,
  isClearable,
  required,
  showTags = true,
}: Props) => {
  const [searchUsers] = useSearchUsersLazyQuery();

  const loadOptions = useDebouncedCallback(async (query: string) => {
    const res = await searchUsers({
      variables: {
        query,
        filters,
      },
    });

    return (
      res.data?.searchUsers?.nodes.map((user) => ({
        id: user.id,
        name: user.name,
        staffMember: showTags ? user.staffMember : undefined,
        driver: user.driver,
      })) ?? []
    );
  }, 150);

  let options = defaultOptions;

  // Add the selected value to the list if not already present
  if (
    Array.isArray(options) &&
    value &&
    !options.find((o) => o.id === value.id)
  ) {
    options = [value, ...options];
  }

  return (
    <AsyncSelect<User, false>
      id={id}
      className={className}
      cacheOptions
      placeholder="Start typing a users name..."
      noOptionsMessage={() => 'No users found'}
      getOptionLabel={(u) => u.name}
      getOptionValue={(u) => u.id}
      value={value}
      defaultOptions={options}
      loadOptions={loadOptions}
      isClearable={isClearable}
      components={{
        Option: UserOption,
      }}
      onChange={onChange}
      required={required}
      menuPortalTarget={globalThis?.document?.querySelector<HTMLElement>(
        '#portal-target',
      )}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 30, // ensure it sits above the dialog which is z-20
        }),
      }}
    />
  );
};
