import { useCommandContext } from '@components/command-palette/useCommandContext';
import { useTasksContext } from '@components/tasks/sidebar/useTasksContext';
import { Tooltip } from '@packfleet/ui';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { BsBook } from 'react-icons/bs';
import {
  TbChartBar,
  TbCheckupList,
  TbCompass,
  TbPrinter,
  TbSearch,
  TbTrees,
} from 'react-icons/tb';
import { Routes } from '../../utilities/routes';

type NavItem = {
  id: string;
  icon: IconType;
  title: string;
  link: Routes;
  subtitle?: string;
  notificationCount?: number | null;
};

const NavItems: NavItem[] = [
  {
    id: 'Dashboard',
    icon: TbChartBar,
    title: 'Dashboard',
    link: Routes.dashboard,
  },
  {
    id: 'Routing',
    icon: TbCompass,
    title: 'Routing',
    subtitle: 'Create routes and oversee activities',
    link: Routes.routing,
  },
  {
    id: 'things',
    icon: TbTrees,
    title: 'Things',
    link: Routes.things,
  },
  {
    id: 'Print',
    icon: TbPrinter,
    title: 'Print',
    subtitle: 'Print labels',
    link: Routes.print,
  },
  {
    id: 'KnowledgeBase',
    icon: BsBook,
    title: 'Knowledge Base',
    subtitle: 'Seek knowledge',
    link: Routes.knowledge,
  },
];

export const Menu = () => {
  const router = useRouter();
  const { setOpen } = useCommandContext();
  const tasksContext = useTasksContext();

  const notificationCount =
    tasksContext.counts?.mine ||
    tasksContext.counts?.team ||
    tasksContext.counts?.mineAndTeamNonUrgent ||
    tasksContext.counts?.all;

  const urgentCount = tasksContext.counts?.mine || tasksContext.counts?.team;

  return (
    <div>
      <ul className="flex md:flex-col gap-3 md:mt-10 ml-3 md:ml-0">
        <MenuItem title="Inbox (⌘ + I)">
          <button
            className={classNames(
              'flex cursor-pointer items-center justify-center rounded-md p-2 relative',
              { 'bg-neutralLight/60': tasksContext.state.sidebarOpen },
            )}
            onClick={tasksContext.action.toggleSidebar}
          >
            <TbCheckupList size={26} className="stroke-neutral" />
            {notificationCount ? (
              <span
                className={classNames(
                  'absolute text-primaryInverted rounded-full text-center',
                  'top-[-.5rem] right-[-.5rem] px-[.4rem] min-w-[1.25rem] min-h-[1.25rem] leading-[1.25rem] text-xs',
                  {
                    'bg-danger': urgentCount,
                    'bg-info': !urgentCount,
                  },
                )}
              >
                {notificationCount}
              </span>
            ) : null}
          </button>
        </MenuItem>

        <MenuItem title="Search (⌘ + K)" className="md:mb-10">
          <button
            className={classNames(
              'flex cursor-pointer items-center justify-center rounded-md p-2',
            )}
            onClick={() => setOpen(true)}
          >
            <TbSearch size={26} className="stroke-neutral" />
          </button>
        </MenuItem>

        {NavItems.map((i) => {
          const isSelected = router.pathname.startsWith(i.link);
          return (
            <MenuItem key={i.id} title={i.title}>
              <Link href={i.link} className="relative block">
                <div>
                  <span
                    className={classNames(
                      'flex cursor-pointer items-center justify-center rounded-md p-2',
                      { 'bg-successLight/80': isSelected },
                    )}
                  >
                    <i.icon size={26} className="stroke-neutral" />
                  </span>
                  {i.notificationCount ? (
                    <span
                      className={classNames(
                        'absolute bg-danger text-primaryInverted rounded-full text-center',
                        'top-[-.5rem] right-[-.5rem] px-[.4rem] min-w-[1.25rem] min-h-[1.25rem] leading-[1.25rem] text-xs',
                      )}
                    >
                      {i.notificationCount}
                    </span>
                  ) : null}
                </div>
              </Link>
            </MenuItem>
          );
        })}
      </ul>
    </div>
  );
};

type MenuItemProps = {
  title: string;
  children: ReactNode;
  className?: string;
};

const MenuItem = ({ title, children, className }: MenuItemProps) => {
  return (
    <li
      className={classNames(
        'inline-block rounded-md hover:bg-secondary',
        className,
      )}
    >
      <Tooltip title={title} position="right" className="z-50">
        {children}
      </Tooltip>
    </li>
  );
};
