import Select from '@/components/select/Select';
import { User, UsersSelect } from '@/components/users/UsersSelect';
import {
  StaffRole,
  useAssignTaskToTeamMutation,
  useAssignTaskToUserMutation,
  useClearTaskAssignmentMutation,
  useListTeamsQuery,
} from '@/generated/graphql';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { extractGraphQLErrorIfExists } from '@/utilities/errors/helpers';
import {
  ContextMenu,
  ContextMenuItem,
} from '@components/routing/menu/ContextMenu';
import { formatFriendlyIso8601DateTimeStr } from '@packfleet/datetime';
import { useState } from 'react';
import { EnrichedTaskInfo } from './useTasksContext';

type Props = {
  task: EnrichedTaskInfo;
};

export const ReassignTaskMenu = ({ task }: Props) => {
  const currentUser = useCurrentUser();
  const [assignTaskToTeam] = useAssignTaskToTeamMutation();
  const [assignTaskToUser] = useAssignTaskToUserMutation();
  const [clearTaskAssignment] = useClearTaskAssignmentMutation();

  const [teamId, setTeamId] = useState<string | null | undefined>(null);
  const [user, setUser] = useState<User | null>(null);

  const { data: teamsData } = useListTeamsQuery({});
  const teams = teamsData?.listTeams?.teams ?? [];

  const isAssigned = !!task.assignedToUser || !!task.assignedToTeam;

  return (
    <ContextMenu
      buttonText={isAssigned ? 'Reassign' : 'Assign'}
      color="primary"
      textAlign="left"
      direction="down"
      align="right"
    >
      {currentUser && task.assignedToUser?.id !== currentUser.id ? (
        <ContextMenuItem
          id="to-me"
          text="To me"
          onClick={async () => {
            return extractGraphQLErrorIfExists(
              assignTaskToUser({
                variables: {
                  input: {
                    taskId: task.id,
                    userId: currentUser?.id,
                  },
                },
              }),
              'assignTaskToUser',
            );
          }}
        />
      ) : null}
      <ContextMenuItem
        id="to-a-user"
        text="To somebody"
        onClick={async () => {
          if (!user) {
            return { error: 'Please select a user' };
          }

          return extractGraphQLErrorIfExists(
            assignTaskToUser({
              variables: {
                input: {
                  taskId: task.id,
                  userId: user?.id,
                },
              },
            }),
            'assignTaskToUser',
          );
        }}
        confirmationDialog={{
          title: 'Assign to a user',
          description: 'Select a user to assign the task to',
          content: (
            <div>
              <UsersSelect
                value={user}
                onChange={(user) => setUser(user)}
                filters={{
                  staffRoles: [
                    StaffRole.Admin,
                    StaffRole.Engineer,
                    StaffRole.Office,
                  ],
                }}
                className="z-30"
                required
              />
            </div>
          ),
        }}
      />
      <ContextMenuItem
        id="to-a-team"
        text="To a team"
        onClick={async () => {
          if (!teamId) {
            return { error: 'Please select a team' };
          }

          return extractGraphQLErrorIfExists(
            assignTaskToTeam({
              variables: {
                input: {
                  taskId: task.id,
                  teamId: teamId,
                },
              },
            }),
            'assignTaskToTeam',
          );
        }}
        confirmationDialog={{
          title: 'Assign to a team',
          description: 'Select a team to assign the task to',
          content: (
            <div>
              <Select
                value={teams.find((t) => t.id === teamId)}
                options={teams}
                getOptionLabel={(team) => team.name}
                getOptionValue={(team) => team.id}
                onChange={(team) => setTeamId(team?.id)}
                required
              />
            </div>
          ),
        }}
      />
      {task.assignedToUser || task.assignedToTeam ? (
        <ContextMenuItem
          id="clear-assignment"
          text="Clear assignment"
          onClick={async () => {
            return extractGraphQLErrorIfExists(
              clearTaskAssignment({
                variables: {
                  input: { taskId: task.id },
                },
              }),
              'clearTaskAssignment',
            );
          }}
          confirmationDialog={{
            title: 'Are you sure?',
            description: task.assignedToUser?.name || task.assignedToTeam?.name,
            content: (
              <div>
                <p>Assigned by {task.assignedByUser?.name}</p>
                {task.assignedAt ? (
                  <p className="text-secondary">
                    {formatFriendlyIso8601DateTimeStr(task.assignedAt)}
                  </p>
                ) : null}
              </div>
            ),
          }}
        />
      ) : null}
    </ContextMenu>
  );
};
