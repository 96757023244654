import DetailNull from '@components/details/DetailNull';
import { Temporal } from '@js-temporal/polyfill';
import {
  TZ_EUROPE_LONDON,
  formatFriendlyDateTime,
  formatRelativeInstant,
} from '@packfleet/datetime';
import { Tooltip } from '@packfleet/ui';
import React from 'react';
import { TbClock } from 'react-icons/tb';

export type Props = {
  value?: Temporal.Instant;
};

const DetailDateTime = ({ value }: Props) => {
  if (value == null) {
    return <DetailNull />;
  }

  return (
    <div className="flex items-center">
      <TbClock className="mr-2 inline" />
      <Tooltip
        title={formatFriendlyDateTime(
          value.toZonedDateTimeISO(TZ_EUROPE_LONDON),
        )}
      >
        <div>{formatRelativeInstant(value)}</div>
      </Tooltip>
    </div>
  );
};

export default DetailDateTime;
