import DetailNull from '@components/details/DetailNull';
import { useCopyToClipboard } from '@packfleet/hooks';
import { Tooltip } from '@packfleet/ui';
import Link from 'next/link';
import React, { useCallback, MouseEvent } from 'react';
import { IoCheckmarkCircleOutline, IoCopyOutline } from 'react-icons/io5';

export type Props = {
  value?: string | null;
  className?: string;
  enableCopyToCliboard: boolean;
  href?: string;
  copyIconClassName?: string;
};

const DetailText = ({
  value,
  className,
  enableCopyToCliboard,
  href,
  copyIconClassName,
}: Props) => {
  const { copied, copyToClipboard } = useCopyToClipboard();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: copyToClipboard
  const onClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();
      value != null && (await copyToClipboard(value));
    },
    [value],
  );

  if (value == null) {
    return <DetailNull />;
  }

  return (
    <>
      <span className={className}>
        {href ? (
          <Link href={href} className="underline">
            {value}
          </Link>
        ) : (
          value
        )}
      </span>
      {enableCopyToCliboard && (
        <Tooltip className="z-50" title="Copy to clipboard" position="right">
          <button className="p-2" onClick={onClick}>
            {copied ? (
              <IoCheckmarkCircleOutline
                color="green"
                className={copyIconClassName}
              />
            ) : (
              <IoCopyOutline className={copyIconClassName} />
            )}
          </button>
        </Tooltip>
      )}
    </>
  );
};

export default DetailText;
