import { GraphQLError } from 'graphql';
import { Error } from '../../generated/graphql';
import { FirebaseAuthError } from './firebase-auth.errors';
import { getErrorMessage } from './get-error-message';

export function getErrorCode(e: {
  code?: FirebaseAuthError;
}): FirebaseAuthError | null {
  if (typeof e.code !== 'undefined') {
    return e.code;
  }
  return null;
}

type Payload<T extends string> =
  | {
      data?: Partial<Record<T, any>> | null;
      errors?: readonly GraphQLError[] | null;
    }
  | null
  | undefined;

export async function extractGraphQLErrorIfExists<
  T extends string,
  Result extends Payload<T>,
>(
  prms: Result | Promise<Result>,
  access: T,
): Promise<{ error?: string; response?: Result }> {
  let response: Result;
  try {
    response = await prms;
  } catch (error) {
    return { error: getErrorMessage(error) };
  }

  if (!response) return {};
  const potentialError = response.data
    ? response.data[access].error
      ? (response.data[access].error as Error | { __typename?: string })
      : (response.data[access] as Error | { __typename?: string })
    : undefined;
  const err = getUserFacingError(potentialError);
  if (err) {
    return { error: err.message, response };
  }
  if (response.errors?.length) {
    const err = response.errors[0];
    return { error: err.message, response };
  }

  return { response };
}

export function getUserFacingError(
  obj: Error | { __typename?: string } | null | undefined,
) {
  if (!obj) return null;
  if (isError(obj)) {
    return obj;
  }
  return null;
}

function isError(obj: Error | { __typename?: string }): obj is Error {
  return obj.__typename === 'Error';
}
