import Checkbox from '@/components/checkbox/Checkbox';
import FormLabel from '@/components/form/FormLabel';
import Input from '@/components/input/Input';
import {
  InternalNoteType,
  useAddInternalNotesMutation,
  useBulkUpdateShipmentDateMutation,
} from '@/generated/graphql';
import { extractGraphQLErrorIfExists } from '@/utilities/errors/helpers';
import { pluralize } from '@/utilities/string';
import { Temporal } from '@js-temporal/polyfill';
import {
  formatFriendlyPlainDateShort,
  formatPlainDate,
  parseOptionalPlainDate,
  parsePlainDate,
} from '@packfleet/datetime';
import { useSetState } from '@packfleet/hooks';
import classNames from 'classnames';
import { chain } from 'lodash';
import { useMemo, useState } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  date: Temporal.PlainDate;
  shipments: {
    id: string;
    trackingPhrase: string;
    organizationName: string;
    perishable: boolean;
    shipment: {
      deliveryDateSLA: string;
    };
  }[];
};

export const MoveDeliveryDateButton = ({ date, shipments }: Props) => {
  const [notes, setNotes] = useState('');
  const [selectedDate, setSelectedDate] = useState(() =>
    formatPlainDate(date.add({ days: 1 })),
  );
  const [excludedShipments, { toggle }] = useSetState<string>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [includeDates, { toggle: toggleIncludeDates }] = useSetState<string>(
    [],
  );

  const [addInternalNotes] = useAddInternalNotesMutation({});
  const [bulkUpdateDate] = useBulkUpdateShipmentDateMutation({});

  const nonPerishableShipments = useMemo(
    () =>
      chain(shipments)
        .filter((s) => !s.perishable)
        .sortBy((s) => s.shipment.deliveryDateSLA)
        .reverse()
        .value(),
    [shipments],
  );
  const groupedByDeliveryDateSLA = useMemo(
    () =>
      chain(nonPerishableShipments)
        .groupBy((s) => s.shipment.deliveryDateSLA)
        .entries()
        .sortBy(([deliveryDateSLA]) => deliveryDateSLA)
        .reverse()
        .value(),
    [nonPerishableShipments],
  );

  const compareTo = useMemo(
    () => parseOptionalPlainDate(selectedDate) ?? date.add({ days: 1 }),
    [selectedDate, date],
  );

  const filteredByDateShipments = useMemo(
    () =>
      nonPerishableShipments.filter(
        (s) =>
          includeDates.has(s.shipment.deliveryDateSLA) ||
          parsePlainDate(s.shipment.deliveryDateSLA).until(compareTo).days <= 0,
      ),
    [nonPerishableShipments, includeDates, compareTo],
  );

  const shipmentIds = useMemo(
    () =>
      new Set(
        filteredByDateShipments
          .filter((s) => !excludedShipments.has(s.id))
          .map((s) => s.id),
      ),
    [filteredByDateShipments, excludedShipments],
  );

  if (!nonPerishableShipments.length) {
    return null;
  }

  return (
    <TaskActionButton
      text="Change delivery date"
      role="secondary"
      dialog={{
        title: 'Change delivery date',
        description: 'This will not impact perishable shipments.',
        body: (
          <div className="flex flex-col gap-4">
            <FormLabel>
              New delivery date
              <Input
                className="block"
                type="date"
                min={formatPlainDate(date.add({ days: 1 }))}
                max={formatPlainDate(date.add({ days: 3 }))}
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.currentTarget.value)}
                required
              />
            </FormLabel>
            <ul>
              {groupedByDeliveryDateSLA.map(([deliveryDateSLA, shipments]) => {
                const numberOfDays = compareTo.since(deliveryDateSLA).days;

                if (numberOfDays <= 0) {
                  return null;
                }

                return (
                  <li>
                    <Checkbox
                      id={deliveryDateSLA}
                      key={deliveryDateSLA}
                      label={`Include ${shipments.length} ${pluralize(shipments.length, 'shipment', 'shipments')} that will be ${numberOfDays} ${pluralize(numberOfDays, 'day', 'days')} late`}
                      onChange={() => toggleIncludeDates(deliveryDateSLA)}
                      checked={includeDates.has(deliveryDateSLA)}
                      className="mt-1"
                    />
                  </li>
                );
              })}
            </ul>

            <FormLabel>
              Notes
              <textarea
                className="block w-full rounded border border-primary p-4 shadow-inner"
                value={notes}
                onChange={(e) => setNotes(e.currentTarget.value)}
                required
                placeholder=""
              />
            </FormLabel>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsExpanded(!isExpanded);
              }}
              type="button"
              className="text-left flex items-center gap-2"
            >
              <BiChevronRight
                className={classNames('transition-transform duration-200', {
                  'rotate-90': isExpanded,
                })}
              />
              {shipmentIds.size}/{nonPerishableShipments.length}{' '}
              {pluralize(
                nonPerishableShipments.length,
                'shipment',
                'shipments',
              )}{' '}
              selected
            </button>
            {isExpanded ? (
              <ul>
                {nonPerishableShipments.map((shipment) => (
                  <li key={shipment.id}>
                    <Checkbox
                      id={shipment.id}
                      onChange={() => toggle(shipment.id)}
                      checked={shipmentIds.has(shipment.id)}
                      disabled={
                        !includeDates.has(shipment.shipment.deliveryDateSLA) &&
                        parsePlainDate(shipment.shipment.deliveryDateSLA).until(
                          compareTo,
                        ).days > 0
                      }
                      className="my-1"
                      label={`${shipment.trackingPhrase} (${shipment.organizationName} - ${formatFriendlyPlainDateShort(parsePlainDate(shipment.shipment.deliveryDateSLA))})`}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        ),
      }}
      onClick={async () => {
        const [bulkUpdateDateResponse, addInternalNotesResponse] =
          await Promise.all([
            extractGraphQLErrorIfExists(
              bulkUpdateDate({
                variables: {
                  input: {
                    updates: Array.from(shipmentIds).map((shipmentId) => ({
                      shipmentID: shipmentId,
                      date: selectedDate,
                      updateEarliestDateToMatch: true,
                    })),
                  },
                },
              }),
              'bulkUpdateShipmentDate',
            ),
            extractGraphQLErrorIfExists(
              addInternalNotes({
                variables: {
                  input: {
                    text: notes,
                    shipmentIds: Array.from(shipmentIds),
                    type: InternalNoteType.Text,
                  },
                },
              }),
              'addInternalNotes',
            ),
          ]);

        return {
          error:
            bulkUpdateDateResponse.error ||
            addInternalNotesResponse.error ||
            undefined,
        };
      }}
    />
  );
};
