// src/comparison.ts
function isMoneyEqual(a, b) {
  return a.amount.toString() === b.amount.toString() && a.currencyCode === b.currencyCode;
}

// src/conversion.ts
import { Money } from "ts-money";
function convertToMoney(m) {
  return new Money(Number(m.amount), m.currencyCode);
}
function toMoney(decimalAmount, currencyCode) {
  const m = Money.fromDecimal(parseFloat(decimalAmount), currencyCode);
  return { amount: BigInt(m.getAmount()), currencyCode: m.getCurrency() };
}
function toMoneyInput(decimalAmount, currencyCode) {
  const m = toMoney(decimalAmount, currencyCode);
  return { amount: m.amount.toString(), currencyCode: m.currencyCode };
}
function toMoneyAmount(decimalAmount, currencyCode) {
  return Money.fromDecimal(parseFloat(decimalAmount), currencyCode).getAmount();
}
function toDecimalString(m) {
  return convertToMoney(m).toString();
}

// src/formatting.ts
function formatMoney(m) {
  const money = convertToMoney(m);
  const sign = money.isNegative() ? "-" : "";
  const symbol = money.getCurrencyInfo().symbol;
  const value = money.multiply(money.isNegative() ? -1 : 1).toDecimal();
  const decimal = value % 1;
  const decimalString = decimal.toFixed(2).slice(1);
  const unit = Math.trunc(value).toLocaleString();
  return `${sign}${symbol}${unit}${decimalString}`;
}
function formatMoneyRounded(m, rounding = Math.trunc) {
  const money = convertToMoney(m);
  const sign = money.isNegative() ? "-" : "";
  const symbol = money.getCurrencyInfo().symbol;
  const value = Math.abs(rounding(money.toDecimal()));
  return `${sign}${symbol}${value.toLocaleString()}`;
}

// src/values.ts
var zeroMoney = (currencyCode) => ({
  amount: "0",
  currencyCode
});

// src/math.ts
function addMoney(a, b) {
  if (a.currencyCode !== b.currencyCode) {
    throw new Error(`Cannot add values of different currency`);
  }
  return {
    currencyCode: a.currencyCode,
    amount: String(BigInt(a.amount) + BigInt(b.amount))
  };
}
function invertMoney(a) {
  return {
    currencyCode: a.currencyCode,
    amount: String(-BigInt(a.amount))
  };
}
function sumMoney(amounts, currencyCode) {
  return amounts.reduce(addMoney, zeroMoney(currencyCode));
}
export {
  addMoney,
  convertToMoney,
  formatMoney,
  formatMoneyRounded,
  invertMoney,
  isMoneyEqual,
  sumMoney,
  toDecimalString,
  toMoney,
  toMoneyAmount,
  toMoneyInput,
  zeroMoney
};
