import { ReactNode } from 'react';
import { useState } from 'react';

import Dialog from '@components/dialog/Dialog';
import FormLabel from '@components/form/FormLabel';
import Input from '@components/input/Input';
import Select from '@components/select/Select';
import {
  InternalNoteType,
  useAddExternalShipmentInternalNoteMutation,
  useAddInternalNoteMutation,
} from '../../generated/graphql';
import { extractGraphQLErrorIfExists } from '../../utilities/errors/helpers';

type Props = {
  trigger: ReactNode;
  shipmentId?: string;
  externalShipmentId?: string;
};

const LinkTypeOptions = [
  { label: 'Conversation with recipient' },
  { label: 'Conversation with merchant' },
  { label: 'Conversation with driver' },
  { label: 'Conversation with external carrier' },
  { label: 'Driver notified of claim' },
  { label: 'Intercom ticket' },
  { label: 'Other link' },
];

export const AddInternalNoteDialog = ({
  shipmentId,
  externalShipmentId,
  trigger,
}: Props) => {
  const [addInternalNote] = useAddInternalNoteMutation();
  const [addExternalShipmentInternalNote] =
    useAddExternalShipmentInternalNoteMutation();

  const [noteText, setNoteText] = useState('');
  const [linkType, setLinkType] = useState<
    null | (typeof LinkTypeOptions)[number]
  >(null);
  const [link, setLink] = useState('');

  return (
    <Dialog
      title="Add internal note"
      description=""
      onConfirm={async () => {
        if (noteText) {
          const res = shipmentId
            ? await extractGraphQLErrorIfExists(
                addInternalNote({
                  variables: {
                    input: {
                      text: noteText,
                      shipmentId: shipmentId,
                      type: InternalNoteType.Text,
                    },
                  },
                }),
                'addInternalNote',
              )
            : externalShipmentId
              ? await extractGraphQLErrorIfExists(
                  addExternalShipmentInternalNote({
                    variables: {
                      input: {
                        text: noteText,
                        externalShipmentId: externalShipmentId,
                        type: InternalNoteType.Text,
                      },
                    },
                  }),
                  'addExternalShipmentInternalNote',
                )
              : null;

          if (res?.error) {
            return res;
          }
        }

        if (link && linkType?.label) {
          const linkRes = shipmentId
            ? await extractGraphQLErrorIfExists(
                addInternalNote({
                  variables: {
                    input: {
                      text: linkType.label,
                      shipmentId: shipmentId,
                      type: InternalNoteType.Link,
                      link,
                    },
                  },
                }),
                'addInternalNote',
              )
            : externalShipmentId
              ? await extractGraphQLErrorIfExists(
                  addExternalShipmentInternalNote({
                    variables: {
                      input: {
                        text: linkType.label,
                        externalShipmentId: externalShipmentId,
                        type: InternalNoteType.Link,
                        link,
                      },
                    },
                  }),
                  'addExternalShipmentInternalNote',
                )
              : null;

          if (linkRes?.error) {
            return linkRes;
          }
        }

        setNoteText('');
        setLinkType(null);
        setLink('');
        return {};
      }}
      confirmText="Add"
      trigger={trigger}
      async
      confirmColor="brand"
    >
      <FormLabel className="mb-4">
        Note
        <textarea
          className="w-full rounded border border-primary p-4 shadow-inner"
          value={noteText}
          required={!link}
          onChange={(e) => setNoteText(e.currentTarget.value)}
        />
      </FormLabel>

      <FormLabel className="mb-4">
        Link
        <Input
          className="block w-full"
          value={link}
          onChange={(e) => setLink(e.currentTarget.value)}
          placeholder="https://app.intercom.com/a/inbox/ma51nwuf/inbox/conversation/..."
          type="text"
          pattern="https?://.+"
        />
      </FormLabel>

      {link ? (
        <>
          <FormLabel className="mb-4">
            Link type
            <Select<(typeof LinkTypeOptions)[number]>
              options={LinkTypeOptions}
              value={linkType}
              onChange={(item) => setLinkType(item)}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ label }) => label}
              required
            />
          </FormLabel>
        </>
      ) : null}
    </Dialog>
  );
};
