import { Routes, linkTo } from '@/utilities/routes';
import { formatFriendlyPlainDate, parsePlainDate } from '@packfleet/datetime';
import {
  AddressReviewFragment,
  AddressValidationEventType,
  useUpdateCollectionLocationAddressMutation,
  useUpdateShipmentDeliveryAddressMutation,
} from 'generated/graphql';
import { useCallback } from 'react';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { AddressIssues } from './utils/AddressIssues';
import { CollectionLocationDetails } from './utils/CollectionLocationDetails';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDetails } from './utils/ShipmentDetails';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: AddressReviewFragment;
  taskId: string;
};

export const AddressReview = ({ task, taskId }: Props) => {
  const [updateDeliveryAddress] = useUpdateShipmentDeliveryAddressMutation();
  const [updateCollectionLocationAddress] =
    useUpdateCollectionLocationAddressMutation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: updateCollectionLocationAddress, updateDeliveryAddress
  const onAcceptSuggestion = useCallback(async () => {
    if (task.validationResult.suggestedAddress == null) {
      return;
    }
    const newAddress = {
      line1: task.validationResult.suggestedAddress.line1,
      line2: task.validationResult.suggestedAddress.line2,
      line3: task.validationResult.suggestedAddress.line3,
      city: task.validationResult.suggestedAddress.city,
      postCode: task.validationResult.suggestedAddress.postCode,
      countryCode: task.validationResult.suggestedAddress.countryCode,
    };
    if (
      task.eventType === AddressValidationEventType.Shipment &&
      task.shipment
    ) {
      return extractGraphQLErrorIfExists(
        updateDeliveryAddress({
          variables: {
            input: {
              id: task.shipment.shipmentId,
              address: newAddress,
            },
          },
        }),
        'updateShipmentDeliveryAddress',
      );
    } else if (
      task.eventType === AddressValidationEventType.CollectionLocation &&
      task.collectionLocation
    ) {
      return extractGraphQLErrorIfExists(
        updateCollectionLocationAddress({
          variables: {
            input: {
              id: task.collectionLocation.collectionLocationId,
              address: newAddress,
            },
          },
        }),
        'updateCollectionLocationAddress',
      );
    }
  }, [task]);

  return (
    <div className="flex flex-col gap-4 py-3">
      {task.eventType === AddressValidationEventType.Shipment &&
      task.shipment ? (
        <>
          <SectionHeading title="Details" />
          <ShipmentDetails shipmentId={task.shipment.shipmentId} />
          <p className="px-3 text-sm">
            <strong className="text-secondary">Delivery date:</strong>{' '}
            {formatFriendlyPlainDate(
              parsePlainDate(task.shipment.deliveryDate),
            )}
          </p>
        </>
      ) : task.collectionLocation?.collectionLocationId ? (
        <>
          <SectionHeading title="Details" />
          <CollectionLocationDetails
            collectionLocationId={task.collectionLocation.collectionLocationId}
          />
        </>
      ) : null}
      <SectionHeading title="Context" />
      <div className="px-3 mx-3 border border-secondary rounded p-4 block">
        <AddressIssues
          address={task.address}
          validationResult={task.validationResult}
        />
      </div>
      <SectionHeading title="Actions" />
      {task.validationResult.suggestedAddress ? (
        <TaskActionButton
          role="primary"
          text="Accept Suggestion"
          taskIdToCompleteOnClick={taskId}
          onClick={onAcceptSuggestion}
        />
      ) : null}
      {task.shipment && (
        <TaskActionButton
          role="secondary"
          text="Manually Edit"
          href={`${linkTo(Routes.shipment, {
            id: task.shipment?.shipmentId,
          })}#recipient-address`}
        />
      )}
    </div>
  );
};
