import React, { useState } from 'react';
import { ColorScheme } from '../button/BaseButton';
import Alert from './Alert';

export type Props = {
  button: React.ReactElement;
  title: string;
  body?: string;
  bodyComponent?: React.ReactNode;
  confirmText: string;
  confirmColor?: ColorScheme;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

const WithAlert = ({ button, ...props }: Props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {React.cloneElement(button, {
        onClick: () => setShow(true),
      })}
      <Alert
        {...props}
        show={show}
        className={'max-w-sm'}
        onConfirm={() => {
          setShow(false);
          props.onConfirm();
        }}
        onCancel={() => {
          setShow(false);
          if (props.onCancel) props.onCancel();
        }}
      />
    </>
  );
};

export default WithAlert;
