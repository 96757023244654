import classNames from 'classnames';
import keyBy from 'lodash.keyby';
import { useMemo, useState } from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';

import { Panel } from '@components/panel/Panel';
import {
  ContextMenu,
  ContextMenuItem,
} from '@components/routing/menu/ContextMenu';
import Skeleton from '@components/skeleton/Skeleton';
import SkeletonList from '@components/skeleton/SkeletonList';
import { formatRelativeInstant, parseInstant } from '@packfleet/datetime';
import { useTaskViewersSubscription } from 'generated/graphql';
import { getTaskInfo } from '../utils';
import { TaskViewer } from './TaskViewer';
import {
  EnrichedTaskInfo,
  TaskCounts,
  filterMineAndTeamNonUrgentTasks,
  filterMyUrgentTasks,
  filterSnoozedTasks,
  filterTeamUrgentTasks,
  useTasksContext,
} from './useTasksContext';

enum Category {
  mine = 'mine',
  team = 'team',
  snoozed = 'snoozed',
  nonUrgent = 'non-urgent',
  all = 'all',
}

export const SidebarContent = () => {
  const {
    action,
    tasks,
    counts,
    error,
    state: { selectedTaskId, viewedTaskIds },
  } = useTasksContext();
  const [currentlyViewing, setCurrentlyViewing] = useState<Category>(
    getDefaulView(counts),
  );
  const { data: taskViewersData } = useTaskViewersSubscription({
    shouldResubscribe: true,
  });

  const tasksWithViewers = useMemo(() => {
    const taskViewersById = keyBy(
      taskViewersData?.taskViewers?.taskViewInfo,
      (t) => t.taskId,
    );

    return tasks?.map((task) => ({
      ...task,
      viewers: taskViewersById[task.id]?.viewers,
    }));
  }, [taskViewersData, tasks]);

  const {
    menuButtonText,
    myTasksText,
    teamTasksText,
    snoozedTasksText,
    teamAndMineNonUrgentTasksText,
    allTasksText,
  } = getButtonText(counts, currentlyViewing);

  return (
    <Panel
      title="Inbox"
      actionsLeft={null}
      actionsRight={
        <>
          <ContextMenu
            buttonText={
              <>
                {!counts ? <Skeleton containerClassName="w-4" /> : null}
                {menuButtonText}
              </>
            }
            direction="down"
            align="right"
            textAlign="center"
          >
            {currentlyViewing !== 'mine' ? (
              <ContextMenuItem
                id="my-tasks"
                text={myTasksText}
                onClick={() => setCurrentlyViewing(Category.mine)}
              />
            ) : null}
            {currentlyViewing !== 'team' ? (
              <ContextMenuItem
                id="team-tasks"
                text={teamTasksText}
                onClick={() => setCurrentlyViewing(Category.team)}
              />
            ) : null}
            {currentlyViewing !== 'snoozed' ? (
              <ContextMenuItem
                id="snoozed-tasks"
                text={snoozedTasksText}
                onClick={() => setCurrentlyViewing(Category.snoozed)}
              />
            ) : null}
            {currentlyViewing !== 'non-urgent' ? (
              <ContextMenuItem
                id="non-urgent-tasks"
                text={teamAndMineNonUrgentTasksText}
                onClick={() => setCurrentlyViewing(Category.nonUrgent)}
              />
            ) : null}
            {currentlyViewing !== 'all' ? (
              <ContextMenuItem
                id="all-tasks"
                text={allTasksText}
                onClick={() => setCurrentlyViewing(Category.all)}
              />
            ) : null}
          </ContextMenu>
          <button
            className="py-1 px-2 shadow rounded-lg border border-light"
            onClick={action.toggleSidebar}
          >
            <IoMdClose />
          </button>
        </>
      }
    >
      <div className="w-full flex flex-col overflow-y-auto">
        {error ? <div className="text-warning">{error}</div> : null}
        <div className="grow shrink flex flex-col items-stretch overflow-y-auto">
          {!tasksWithViewers ? (
            <SkeletonList count={5} className="p-2" height={78} />
          ) : null}
          {filterTasks(tasksWithViewers, currentlyViewing).map((taskInfo) => {
            const { title, subject } = getTaskInfo(taskInfo.task);
            return (
              <button
                key={taskInfo.id}
                onClick={() => action.selectTask(taskInfo.id)}
                className={classNames(
                  'min-h-[78px] border-b border-light flex flex-col pr-4 pl-6 py-2 relative',
                  { 'bg-infoLight/50': selectedTaskId === taskInfo.id },
                )}
              >
                <div className="self-stretch flex items-center">
                  {!taskInfo.isRead && !viewedTaskIds[taskInfo.id] ? (
                    <BsFillCircleFill
                      className="shrink-0 text-[#2f4f7e] -ml-[1.3rem] w-[1.3rem]"
                      size={8}
                    />
                  ) : null}
                  <div className="grow flex justify-between gap-2 min-w-0">
                    <span className="font-bold text-brandDark text-left truncate text-sm">
                      {title}
                    </span>
                    <span className="text-secondaryInverted font-medium text-sm whitespace-nowrap">
                      {formatRelativeInstant(taskInfo.createdAt)}
                    </span>
                  </div>
                </div>
                <div className="text-xs text-secondary py-1 text-left">
                  {subject}
                </div>
                <div className="self-stretch flex justify-between">
                  <div className="flex group">
                    {taskInfo.viewers?.map((v, index) => (
                      <TaskViewer
                        key={v.user.id}
                        index={index}
                        name={v.user.name}
                        updatedAt={parseInstant(v.updatedAt)}
                      />
                    ))}
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="text-secondaryInverted font-medium text-xs whitespace-nowrap">
                      {currentlyViewing === Category.team &&
                      taskInfo.assignedToMyTeamName
                        ? taskInfo.assignedToMyTeamName
                        : taskInfo.assignedToMyRoleName ||
                          taskInfo.assignedToMyTeamName}
                    </span>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </Panel>
  );
};

function filterTasks<T extends EnrichedTaskInfo>(
  tasksWithViewers: T[] | undefined,
  currentlyViewing: Category,
): T[] {
  if (!tasksWithViewers) {
    return [];
  }

  switch (currentlyViewing) {
    case Category.mine:
      return tasksWithViewers.filter(filterMyUrgentTasks);
    case Category.team:
      return tasksWithViewers.filter(filterTeamUrgentTasks);
    case Category.nonUrgent:
      return tasksWithViewers.filter(filterMineAndTeamNonUrgentTasks);
    case Category.snoozed:
      return tasksWithViewers.filter(filterSnoozedTasks);
    case Category.all:
      return tasksWithViewers;
  }
}

function getButtonText(counts: TaskCounts | null, currentlyViewing: string) {
  const myTasksText = getText(counts?.mine, 'My Tasks');
  const teamTasksText = getText(counts?.team, 'Team');
  const snoozedTasksText = getText(counts?.snoozed, 'Snoozed');
  const teamAndMineNonUrgentTasksText = getText(
    counts?.mineAndTeamNonUrgent,
    'Non-urgent',
  );
  const allTasksText = getText(counts?.all, 'All');

  const menuButtonText =
    currentlyViewing === 'mine'
      ? myTasksText
      : currentlyViewing === 'team'
        ? teamTasksText
        : currentlyViewing === 'snoozed'
          ? snoozedTasksText
          : currentlyViewing === 'non-urgent'
            ? teamAndMineNonUrgentTasksText
            : allTasksText;

  return {
    menuButtonText,
    myTasksText,
    teamTasksText,
    snoozedTasksText,
    teamAndMineNonUrgentTasksText,
    allTasksText,
  };
}

function getText(count: number | null | undefined, text: string) {
  return (
    <div className="flex items-center justify-between gap-2 w-full px-1">
      <span>{text}</span>
      <span>{count ?? ''}</span>
    </div>
  );
}

function getDefaulView(counts: TaskCounts | null): Category {
  if (!counts || counts.mine > 0) {
    return Category.mine;
  }

  if (counts.team > 0) {
    return Category.team;
  }

  if (counts.mineAndTeamNonUrgent > 0) {
    return Category.nonUrgent;
  }

  if (counts.all > 0) {
    return Category.all;
  }

  return Category.mine;
}
