import { getInitials } from '@/utilities/string';
import { Temporal } from '@js-temporal/polyfill';
import { now } from '@packfleet/datetime';
import { Tooltip } from '@packfleet/ui';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { STALE_AGE_MS } from './constants';

const timeUntilStaleMs = (updatedAt: Temporal.Instant) => {
  return STALE_AGE_MS - now().since(updatedAt).total('milliseconds');
};

type Props = {
  index: number;
  name: string;
  updatedAt: Temporal.Instant;
};

export const TaskViewer = ({ index, name, updatedAt }: Props) => {
  const [isStale, setIsStale] = useState(timeUntilStaleMs(updatedAt) >= 0);

  useEffect(() => {
    const remainingMs = timeUntilStaleMs(updatedAt);
    if (remainingMs >= 0) {
      setIsStale(false);
      const timer = setTimeout(() => {
        setIsStale(true);
      }, remainingMs);
      return () => clearTimeout(timer);
    } else {
      setIsStale(true);
    }
  }, [updatedAt]);

  return (
    <div
      className={classNames(
        'flex items-center rounded-full bg-infoLight/50 text-brandDark p-1 text-[8px] transition-[margin-left]',
        {
          '-ml-2 group-hover:ml-1': index !== 0,
          'bg-infoLight/10 text-opacity-20': isStale,
        },
      )}
    >
      <Tooltip title={name} className="z-50">
        <span>{getInitials(name)}</span>
      </Tooltip>
    </div>
  );
};
