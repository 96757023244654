import { Routes, linkTo } from '@/utilities/routes';
import { TZ } from '@/utilities/timezone';
import {
  formatInstantTime,
  isInstantAfter,
  now,
  parseOptionalInstant,
} from '@packfleet/datetime';
import { VehicleNotEndedFragment } from 'generated/graphql';
import { SectionHeading } from './utils/SectionHeading';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: VehicleNotEndedFragment;
  taskId: string;
};

export const VehicleNotEnded = ({ task }: Props) => {
  const endTime = parseOptionalInstant(task.vehicle.endTime);
  const shiftEndTime = parseOptionalInstant(task.vehicle.shiftEndTime);

  return (
    <div className="flex flex-col gap-4 py-3">
      <SectionHeading title="Context" />
      <p className="px-3 text-sm">
        You should get in touch with the driver to check if they need help
        ending their route.
      </p>
      {endTime && shiftEndTime ? (
        <p className="px-3 text-sm">
          Their route {isInstantAfter(endTime, now()) ? 'is' : 'was'} due to end
          at {formatInstantTime(endTime, TZ)}, and their shift{' '}
          {isInstantAfter(shiftEndTime, now()) ? 'ends' : 'ended'} at{' '}
          {formatInstantTime(shiftEndTime, TZ)}.
        </p>
      ) : null}

      <SectionHeading title="Actions" />
      <TaskActionButton
        role="primary"
        text="View route"
        href={linkTo(Routes.routeActiveDriver, {
          date: task.vehicle.routeDate,
          vehicleId: task.vehicle.id,
        })}
      />
    </div>
  );
};
