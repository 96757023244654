import React from 'react';
import ReactSkeleton, { SkeletonProps } from 'react-loading-skeleton';

export type Props = SkeletonProps;

export const Skeleton = (props: Props) => {
  return <ReactSkeleton {...props} />;
};

export const SkeletonRow = (props: Props) => {
  return (
    <div className="flex flex-row gap-8">
      {new Array(props.count ?? 1).fill(null).map((_, index) => (
        <Skeleton {...props} count={1} key={index} />
      ))}
    </div>
  );
};

export default Skeleton;
