import { uniq } from 'lodash';
import { useMemo } from 'react';

export const useMoneyFormatters = (currencies: string[]) => {
  return useMemo(
    () =>
      Object.fromEntries(
        uniq(currencies).map(
          (currency) =>
            [
              currency,
              new Intl.NumberFormat('en-GB', { style: 'currency', currency }),
            ] as const,
        ),
      ),
    [currencies],
  );
};

export const useMoneyFormatter = (currency: string) => {
  return useMemo(
    () => new Intl.NumberFormat('en-GB', { style: 'currency', currency }),
    [currency],
  );
};
