import { useMoneyFormatter } from '@/hooks/useMoneyFormatter';
import { extractGraphQLErrorIfExists } from '@/utilities/errors/helpers';
import { Routes, linkTo } from '@/utilities/routes';
import {
  formatFriendlyPlainDate,
  parseInstant,
  parseOptionalInstant,
  parsePlainDate,
} from '@packfleet/datetime';
import { groupBy } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { IoDocumentOutline } from 'react-icons/io5';
import { TbTrash } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import { ClaimFragment, useDeleteClaimMutation } from '../../generated/graphql';
import DetailDateTime from '../details/DetailDateTime';
import DetailLink from '../details/DetailLink';
import DetailRow from '../details/DetailRow';
import DetailText from '../details/DetailText';
import { Details } from '../details/Details';
import Pill from '../pill/Pill';
import { renderSourceLabel } from './Claim';
import { formatClaimStatus, getClaimBadgeColor } from './utilities';

export type Props = {
  claim: ClaimFragment;
  className?: string;
};

export const ClaimDetails = ({ claim, className }: Props) => {
  const [deleteClaim] = useDeleteClaimMutation();
  const router = useRouter();

  const moneyFormatter = useMoneyFormatter(claim.currency ?? 'GBP');

  return (
    <Details
      title={
        <>
          <span>Claim</span>
          <Pill
            color={getClaimBadgeColor(claim.status)}
            className="text-sm ml-2"
          >
            {formatClaimStatus(claim.status)}
          </Pill>
        </>
      }
      actions={[
        {
          icon: TbTrash,
          destructive: true,
          onClickShowConfirmation: {
            title: 'Delete claim',
            description:
              'This action cannot be undone. Are you sure you want to delete this claim?',
            confirmText: 'Yes, delete',
            cancelText: 'No',
          },
          onAction: async () => {
            const res = await extractGraphQLErrorIfExists(
              deleteClaim({ variables: { input: { id: claim.id } } }),
              'deleteClaim',
            );
            if (res.error) {
              return res;
            }
            await router.push(linkTo(Routes.claims));
            return {};
          },
        },
      ]}
      className={twMerge('w-details rounded-md bg-primary pb-20', className)}
    >
      <DetailRow
        name="Amount"
        data={claim.amount ? moneyFormatter.format(claim.amount / 100) : '£TBC'}
      >
        {(props) => <DetailText {...props} />}
      </DetailRow>
      {claim.costToPackfleet && (
        <DetailRow
          name="Cost to Packfleet"
          data={moneyFormatter.format(claim.costToPackfleet / 100)}
        >
          {(props) => <DetailText {...props} />}
        </DetailRow>
      )}
      <DetailRow
        name="Source User Charged"
        data={claim.chargeSourceUser ? 'Yes' : 'No'}
      >
        {(props) => <DetailText {...props} enableCopyToCliboard={false} />}
      </DetailRow>
      <DetailRow
        name="Shipping Fees Refunded"
        data={claim.refundFees ? 'Yes' : 'No'}
      >
        {(props) => <DetailText {...props} enableCopyToCliboard={false} />}
      </DetailRow>
      <DetailRow name="Type" data={claim.type}>
        {(props) => <DetailText {...props} />}
      </DetailRow>
      <DetailRow name="Source" data={renderSourceLabel(claim)}>
        {(props) => <DetailText {...props} />}
      </DetailRow>
      <DetailRow name="Description" data={claim.reason}>
        {(props) => <DetailText {...props} />}
      </DetailRow>
      {claim.shipment ? (
        <DetailRow name="Shipment" data={claim.shipment.trackingNumber}>
          {(props) => (
            <DetailLink
              {...props}
              href={linkTo(Routes.shipment, {
                id: claim.shipment?.id,
              })}
            />
          )}
        </DetailRow>
      ) : null}
      {claim.externalShipment ? (
        <DetailRow
          name="Shipment"
          data={`${claim.externalShipment.carrierName} ${claim.externalShipment.trackingNumber}`}
        >
          {(props) => (
            <DetailLink
              {...props}
              href={linkTo(Routes.externalShipment, {
                id: claim.externalShipment?.id,
              })}
            />
          )}
        </DetailRow>
      ) : null}
      {claim.collection ? (
        <DetailRow
          name="Collection"
          data={formatFriendlyPlainDate(parsePlainDate(claim.collection.date))}
        >
          {(props) => (
            <DetailLink
              {...props}
              href={linkTo(Routes.merchantCollection, {
                id: claim.organization?.id,
                collectionId: claim.collection?.id,
              })}
            />
          )}
        </DetailRow>
      ) : null}
      <DetailRow name="Created by" data={claim.createdByUser?.name}>
        {(props) => (
          <DetailLink
            {...props}
            href={
              claim.createdByUser
                ? linkTo(Routes.user, { id: claim.createdByUser.id })
                : ''
            }
          />
        )}
      </DetailRow>
      <DetailRow name="Approved by" data={claim.approvedByUser?.name}>
        {(props) => (
          <DetailLink
            {...props}
            href={
              claim.approvedByUser
                ? linkTo(Routes.user, { id: claim.approvedByUser.id })
                : ''
            }
          />
        )}
      </DetailRow>
      <DetailRow name="Created" data={parseInstant(claim.createdAt)}>
        {(props) => <DetailDateTime {...props} />}
      </DetailRow>
      <DetailRow name="Updated" data={parseOptionalInstant(claim.updatedAt)}>
        {(props) => <DetailDateTime {...props} />}
      </DetailRow>
      <DetailRow name="Approved" data={parseOptionalInstant(claim.approvedAt)}>
        {(props) => <DetailDateTime {...props} />}
      </DetailRow>
      <DetailRow name="Supporting Documents" data={claim.supportingDocuments}>
        {(props) => {
          // Group by document type
          const groupedDocuments = groupBy(props.value, 'documentType');

          return (
            <div className="mt-4 mb-6 px-4 w-full flex flex-col gap-6">
              {Object.entries(groupedDocuments).map(
                ([documentType, documents]) => (
                  <div key={documentType}>
                    <div className="mt-1 text-sm text-secondary">
                      {documentType}
                    </div>

                    {documents.map((doc) => (
                      <div
                        className="relative mt-4 grid grid-flow-col gap-4"
                        style={{ maxWidth: 200 }}
                        key={doc.filePath}
                      >
                        <div className="relative" key={doc.imageUrl}>
                          <a
                            href={doc.imageUrl}
                            target="_blank"
                            download
                            rel="noreferrer"
                          >
                            <div className="flex gap-1 items-center">
                              <IoDocumentOutline size={24} />
                              <div className="text-md overflow-ellipsis max-w-[320px] overflow-hidden">
                                {doc.filePath.split('-').pop()}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              )}
            </div>
          );
        }}
      </DetailRow>
    </Details>
  );
};
