import DetailNull from '@components/details/DetailNull';
import Link from '@components/link/Link';
import React, { ComponentProps } from 'react';

export type Props = {
  value?: string | null;
} & ComponentProps<typeof Link>;

const DetailLink = ({ value, ...props }: Props) => {
  if (value == null) {
    return <DetailNull />;
  }

  return <Link {...props}>{value}</Link>;
};

export default DetailLink;
