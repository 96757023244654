import {
  ClaimFragment,
  ClaimStatus,
  ListClaimsDocument,
  useReviewClaimMutation,
} from '@/generated/graphql';
import { extractGraphQLErrorIfExists } from '@/utilities/errors/helpers';
import { truthy } from '@/utilities/truthy';
import { upperFirst } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import Dialog from '../dialog/Dialog';
import FormLabel from '../form/FormLabel';
import Select from '../select/Select';

type Props = {
  claim: ClaimFragment;
  setError: (error: string | null | undefined) => void;
  trigger: ReactNode;
};

export const ClaimRejection = ({ claim, setError, trigger }: Props) => {
  const [reviewClaim] = useReviewClaimMutation();
  const [internalNote, setInternalNote] = useState('');
  const [reason, setReason] = useState<string | null | undefined>(null);

  const reasons = useMemo(
    () =>
      [
        'the item wasn’t appropriately packaged',
        'the delivery instructions were followed',
        claim.externalShipmentId
          ? 'the claim is not covered under the the delivery partner’s claim policy'
          : null,
      ]
        .filter(truthy)
        .map((reason) => ({
          label: upperFirst(reason),
          value: reason,
        })),
    [claim.externalShipmentId],
  );

  return (
    <Dialog
      trigger={trigger}
      title="Are you sure?"
      confirmText="Yes, reject"
      confirmColor="danger"
      cancelText="Cancel"
      onConfirm={async () => {
        const { error } = await extractGraphQLErrorIfExists(
          reviewClaim({
            variables: {
              input: {
                id: claim.id,
                status: ClaimStatus.Rejected,
                updateReason: reason,
                internalNote,
              },
            },
            refetchQueries: [ListClaimsDocument],
          }),
          'reviewClaim',
        );

        setError(error);
      }}
    >
      <FormLabel>
        Reason
        <Select
          required
          options={reasons}
          value={reasons.find((r) => r.value === reason)}
          onChange={(e) => setReason(e?.value)}
        />
      </FormLabel>
      <FormLabel className="mt-4">
        Internal note
        <textarea
          className="w-full block rounded border border-primary p-4 shadow-inner"
          placeholder="A glass bottle in a paper bag..."
          required
          value={internalNote}
          onChange={(e) => setInternalNote(e.currentTarget.value)}
        />
      </FormLabel>
    </Dialog>
  );
};
