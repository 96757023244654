import {
  ClaimFragment,
  ClaimSourceType,
  ListClaimsDocument,
  StaffRole,
  useUpdateClaimMutation,
} from '@/generated/graphql';
import Dialog from '@components/dialog/Dialog';
import FormLabel from '@components/form/FormLabel';
import { toMoneyAmount } from '@packfleet/money';
import { ReactNode } from 'react';
import { useState } from 'react';
import { extractGraphQLErrorIfExists } from '../../utilities/errors/helpers';
import FormDescription from '../form/FormDescription';
import Input from '../input/Input';
import Select from '../select/Select';
import { ToggleButtons } from '../toggle-button/ToggleButtons';
import { User, UsersSelect } from '../users/UsersSelect';
import { ClaimTypeOptions } from './AddClaimDialog';
import { ClaimAmountType } from './Claim';

type Props = {
  claim: ClaimFragment;
  trigger: ReactNode;
};

export const SourceTypeOptions = [
  { label: 'Driver', value: ClaimSourceType.Driver },
  { label: 'Hub', value: ClaimSourceType.Hub },
  { label: 'Internal', value: ClaimSourceType.Internal },
  { label: 'External Carrier', value: ClaimSourceType.ExternalCarrier },
];

export const EditClaimDialog = ({ claim, trigger }: Props) => {
  const [updateClaim] = useUpdateClaimMutation();

  const [type, setType] = useState(claim.type);
  const [reason, setReason] = useState(claim.reason ?? '');
  const [sourceType, setSourceType] = useState<null | ClaimSourceType>(
    claim.sourceType ?? null,
  );
  const [sourceUser, setSourceUser] = useState<User | null | undefined>(
    claim.sourceUser,
  );
  const [externalReference, setExternalReference] = useState(
    claim.externalReference ?? '',
  );

  const [amountsType, setAmountsType] = useState<number | boolean>(
    claim.amount != null
      ? claim.amount > 0
        ? ClaimAmountType.Credit
        : ClaimAmountType.Charge
      : false,
  );
  const [amount, setAmount] = useState<string | null>(
    claim.amount ? (Math.abs(claim.amount) / 100).toFixed(2) : null,
  );
  const [costToPackfleet, setCostToPackfleet] = useState<string | null>(
    claim.costToPackfleet ? (claim.costToPackfleet / 100).toFixed(2) : null,
  );
  const [chargeSourceUser, setChargeSourceUser] = useState<boolean>(
    claim.chargeSourceUser ?? false,
  );
  const [chargeSourceUserAmount, setChargeSourceUserAmount] = useState<
    string | null
  >(
    claim.chargeSourceUserAmount
      ? (Math.abs(claim.chargeSourceUserAmount) / 100).toFixed(2)
      : null,
  );
  const [refundFees, setRefundFees] = useState<boolean>(
    claim.refundFees ?? false,
  );

  return (
    <Dialog
      title="Edit claim"
      description=""
      onConfirm={async () => {
        const currency = claim.currency || 'GBP';
        const amountVal =
          amountsType !== false && amount
            ? toMoneyAmount(amount, currency) * Number(amountsType)
            : null;
        const costToPackfleetVal = costToPackfleet
          ? toMoneyAmount(costToPackfleet, currency)
          : null;
        const chargeSourceUserAmountVal = chargeSourceUserAmount
          ? toMoneyAmount(chargeSourceUserAmount, currency)
          : null;

        const res = await extractGraphQLErrorIfExists(
          updateClaim({
            variables: {
              input: {
                id: claim.id,
                type,
                reason: reason || null,
                currency,
                amount: amountVal,
                costToPackfleet: costToPackfleetVal,
                chargeSourceUser,
                chargeSourceUserAmount: chargeSourceUser
                  ? (chargeSourceUserAmountVal ?? amountVal)
                  : null,
                refundFees,
                sourceType,
                externalReference,
                sourceUserId: sourceUser?.id,
              },
            },
            refetchQueries: [
              {
                query: ListClaimsDocument,
                variables: {
                  input: {
                    shipmentId: claim.shipmentId,
                    externalShipmentId: claim.externalShipmentId,
                  },
                },
              },
            ],
          }),
          'updateClaim',
        );

        if (res.error) {
          return res;
        }

        const updatedClaim = res.response?.data?.updateClaim?.claim;

        setType(updatedClaim?.type ?? '');
        setReason(updatedClaim?.reason ?? '');
        setAmount(
          updatedClaim?.amount
            ? (Math.abs(updatedClaim.amount) / 100).toFixed(2)
            : null,
        );
        setCostToPackfleet(
          updatedClaim?.costToPackfleet
            ? (Math.abs(updatedClaim.costToPackfleet) / 100).toFixed(2)
            : null,
        );
        setChargeSourceUser(updatedClaim?.chargeSourceUser ?? false);
        setRefundFees(updatedClaim?.refundFees ?? false);

        return {};
      }}
      confirmText="Update"
      trigger={trigger}
      async
      confirmColor="brand"
    >
      <div>
        <FormLabel className="mb-4">
          Claim type
          <Select<(typeof ClaimTypeOptions)[number]>
            options={ClaimTypeOptions}
            value={ClaimTypeOptions.find((item) => item.label === type)}
            onChange={(item) => setType(item?.label ?? '')}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ label }) => label}
            required
          />
        </FormLabel>

        <FormLabel className="mb-4">
          Source type
          <Select<(typeof SourceTypeOptions)[number]>
            options={SourceTypeOptions}
            value={SourceTypeOptions.find((item) => item.value === sourceType)}
            onChange={(item) => setSourceType(item?.value ?? null)}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ value }) => value}
            required
          />
        </FormLabel>

        {sourceType &&
          [ClaimSourceType.Driver, ClaimSourceType.Hub].includes(
            sourceType,
          ) && (
            <FormLabel className="mb-4">
              Source user
              <UsersSelect
                value={sourceUser}
                onChange={(user) => setSourceUser(user)}
                filters={{
                  staffRoles: [StaffRole.Driver, StaffRole.Warehouse],
                }}
              />
            </FormLabel>
          )}

        {sourceType === ClaimSourceType.ExternalCarrier ? (
          <FormLabel className="mb-4">
            External reference
            <Input
              className="w-full"
              value={externalReference}
              onChange={(e) => setExternalReference(e.currentTarget.value)}
            />
          </FormLabel>
        ) : null}

        <FormLabel className="mb-4">
          Comments
          <textarea
            className="w-full rounded border border-primary p-4 shadow-inner"
            value={reason}
            onChange={(e) => setReason(e.currentTarget.value)}
          />
        </FormLabel>

        <div className="mb-4">
          <FormLabel>Amounts</FormLabel>
          <ToggleButtons
            buttons={[
              { label: 'Credit', value: 1 },
              { label: 'Charge', value: -1 },
              { label: 'Not yet known', value: false },
            ]}
            value={amountsType}
            onClick={(v) => {
              if (v !== false) {
                setAmountsType(v);

                if (v === ClaimAmountType.Charge) {
                  setRefundFees(false);
                }
              } else {
                setAmountsType(false);
                setAmount(null);
                setCostToPackfleet(null);
              }
            }}
          />
          {amountsType !== false && (
            <div className="pt-2">
              <FormLabel className="mb-4">
                Claim Amount
                <Input
                  className="w-full"
                  value={amount ?? ''}
                  onChange={(e) => setAmount(e.currentTarget.value)}
                  placeholder="0.00"
                />
                {amountsType === ClaimAmountType.Credit && (
                  <FormDescription className="mt-1" id="amount">
                    Cost price amount only.
                    <br />
                    Use the option below to refund shipping fees.
                  </FormDescription>
                )}
              </FormLabel>
              <FormLabel className="mb-4">
                Cost to Packfleet (optional)
                <Input
                  className="w-full"
                  value={costToPackfleet ?? ''}
                  onChange={(e) => setCostToPackfleet(e.currentTarget.value)}
                  placeholder="0.00"
                />
              </FormLabel>
            </div>
          )}
          {amountsType === ClaimAmountType.Credit && (
            <div className="pt-2">
              <FormLabel className="mb-4">
                Charge Source
                <ToggleButtons
                  buttons={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  value={chargeSourceUser}
                  onClick={(v) => setChargeSourceUser(v)}
                />
              </FormLabel>
            </div>
          )}
          {chargeSourceUser && (
            <FormLabel className="mb-4">
              Amount to be charged to source user
              <Input
                className="w-full"
                value={chargeSourceUserAmount ?? ''}
                onChange={(e) =>
                  setChargeSourceUserAmount(e.currentTarget.value)
                }
                placeholder={amount ?? '0.00'}
                inputMode="numeric"
                pattern="[0-9\.]*"
              />
            </FormLabel>
          )}
          {amountsType === ClaimAmountType.Credit && (
            <div className="pt-2">
              <FormLabel className="mb-4">
                Refund Shipping Fees
                <ToggleButtons
                  buttons={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  value={refundFees}
                  onClick={(v) => setRefundFees(v)}
                />
              </FormLabel>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
