import {
  formatFriendlyPlainDateShort,
  parsePlainDate,
} from '@packfleet/datetime';
import {
  RouteActivityDetailsFragment,
  useReadOptimizedRouteVehicleSequenceNumbersQuery,
} from 'generated/graphql';
import Link from 'next/link';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Routes, linkTo } from 'utilities/routes';

type Props = {
  routeActivity: RouteActivityDetailsFragment;
  title: string | null;
};

export const RouteActivity = ({ routeActivity, title }: Props) => {
  const { data, loading, error } =
    useReadOptimizedRouteVehicleSequenceNumbersQuery({
      variables: { id: routeActivity.vehicleId.toString() },
    });

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: routeActivity.routeActivityId.toString
  const activity = useMemo(() => {
    const routeActivityId = routeActivity.routeActivityId.toString();
    return data?.readOptimizedRouteVehicle?.vehicle?.activities?.find(
      (a) => a.id === routeActivityId,
    );
  }, [data]);

  return (
    <div className="px-3">
      <p className="text-sm text-secondary">{title}</p>
      {error?.message ? <p className="text-warning">{error.message}</p> : null}
      <Link
        href={linkTo(Routes.routeActiveDriverActivity, {
          date: routeActivity.date,
          vehicleId: routeActivity.vehicleId.toString(),
          activityId: routeActivity.routeActivityId.toString(),
        })}
        className="text-link"
      >
        {routeActivity.driver?.name}{' '}
        {formatFriendlyPlainDateShort(parsePlainDate(routeActivity.date))}{' '}
        {loading ? (
          <Skeleton width={25} height={25} className="inline-block" />
        ) : null}
        {activity ? (
          <span className="bg-info text-primaryInverted rounded-full px-2 py-1">
            {activity.sequence}
          </span>
        ) : null}
      </Link>
    </div>
  );
};
