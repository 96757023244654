import Dialog from '@components/dialog/Dialog';
import { Button, IconButton, Tooltip } from '@packfleet/ui';
import React from 'react';
import { IconType } from 'react-icons';

export type DetailActionType = {
  tooltip?: string;
  label?: string;
  icon: IconType;
  onAction: () => Promise<{ error?: string }>;
  onClickShowConfirmation?: {
    title: string;
    description: string;
    cancelText?: string;
    confirmText?: string;
    loadingText?: string;
  };
  destructive?: boolean;
};

export type Props = {
  action: DetailActionType;
};

const DetailAction = ({ action }: Props) => {
  const button = action.label ? (
    <Button
      s="smaller"
      mode="secondary"
      color="neutral"
      onClick={action.onClickShowConfirmation ? undefined : action.onAction}
    >
      {action.icon ? <action.icon className="mr-2 text-2xl" /> : null}
      {action.label}
    </Button>
  ) : (
    <IconButton
      icon={action.icon}
      iconClassName="text-2xl"
      onClick={action.onClickShowConfirmation ? undefined : action.onAction}
    />
  );

  const inner = action.onClickShowConfirmation ? (
    <Dialog
      trigger={button}
      title={action.onClickShowConfirmation.title}
      description={action.onClickShowConfirmation.description}
      onConfirm={action.onAction}
      cancelText={action.onClickShowConfirmation.cancelText}
      confirmText={action.onClickShowConfirmation.confirmText}
      confirmColor={action.destructive ? 'danger' : 'success'}
      async
    />
  ) : (
    button
  );
  if (!action.tooltip) {
    return <div>{inner}</div>;
  }
  return (
    <Tooltip offset={10} title={action.tooltip}>
      <div>{inner}</div>
    </Tooltip>
  );
};

export default DetailAction;
