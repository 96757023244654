import React, { useEffect, useRef } from 'react';
import { ColorScheme } from '../button/BaseButton';
import Button from '../button/Button';
import Heading from '../heading/Heading';
import Modal from './Modal';
import ModalContent from './ModalContent';

export type Props = {
  show: boolean;
  title: string;
  body?: string;
  bodyComponent?: React.ReactNode;
  confirmText: string;
  confirmColor?: ColorScheme;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  className?: string;
};

const Alert = ({
  show,
  title,
  body,
  bodyComponent,
  confirmText,
  confirmColor = 'brand',
  cancelText,
  onConfirm,
  className,
  onCancel,
}: Props) => {
  const mainRef = useRef<HTMLElement | undefined>(undefined);
  useEffect(() => {
    mainRef.current = document.getElementsByTagName('main')[0];
  });
  return (
    <Modal
      isOpen={show}
      onClose={onCancel ? onCancel : onConfirm}
      appElement={mainRef.current}
    >
      <ModalContent
        className={className}
        onClose={onCancel ? onCancel : onConfirm}
      >
        <Heading level={2} className="text-primary">
          {title}
        </Heading>
        {body ? (
          <p className="text-secondary">{body}</p>
        ) : bodyComponent ? (
          bodyComponent
        ) : null}
        <div className="mt-8 flex justify-end">
          {cancelText ? (
            <Button
              onClick={onCancel}
              mode="outline"
              color="neutral"
              className="mr-2"
            >
              {cancelText}
            </Button>
          ) : null}
          <Button onClick={onConfirm} color={confirmColor}>
            {confirmText}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default Alert;
