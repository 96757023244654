export function pluralize(count: number, singular: string, plural: string) {
  if (count === 1) return singular;
  return plural;
}

/**
 * Turns a string into it's initials
 * @param name The string to initialize
 * @returns The initials
 *
 * @example
 * getInitials('John Smith') // 'JS'
 */
export function getInitials(name: string | null | undefined) {
  return (
    name
      ?.match(/\b(\w)/g)
      ?.join('')
      .substring(0, 2) || ''
  );
}

export function initializeSurname(str: string | null | undefined): string {
  if (!str) {
    return '';
  }

  return str
    .split(/[ -]/g)
    .map((name, index) => (index === 0 ? `${name} ` : name.charAt(0)))
    .join('')
    .trimEnd();
}
